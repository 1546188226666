import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { makeApiRequest } from '../../../config/AxiosService';
import { CiCircleRemove } from "react-icons/ci";
import { useNavigate } from 'react-router-dom';
import DataContext from "../../../context/DataContext"
import { Spinner } from 'react-bootstrap';

function Ubo({ handlePrevious, loggedin, handleParentSubmit }) {
    const navigate = useNavigate();
    const { setIsLogedIn } = useContext(DataContext);
    const [uboGetData, setUboGetData] = useState({})
    const [isOfficial1, setIsOfficial1] = useState(null);
    const [isOfficial2, setIsOfficial2] = useState(null);
    const [uploadedImage, setUploadedImage] = useState(null);
    const [showImage, setShowImage] = useState(null);
    const [uboData, setUboData] = useState({
        ubo: {
            first_name: "",
            last_name: "",
            patronymic: "",
            dob: "",
            birth_place: "",
            citizenship: "",
            company_shareCapital: "",
            domicile: "",
            actual_address: "",
            source_wealth: "",
            tax_residency: "",
            tax_number: "",
            public_officials_1: {
                officials_1: false,
                specify_official_1: "",
            },
            public_officials_2: {
                officials_2: false,
                specify_official_2: "",
                position_held: "",
                period: "",
                relationship: "",
            },
            phone_number: "",
            passport_information: {
                document_name: "",
                number: "",
                issuing_body: "",
                expiry_date: "",
                business_activity: "",
                signature: "",
            }
        }
    });

    const toggleLogin = () => {
        setIsLogedIn(true);
        navigate('/');
    };

    const {
        register: register3,
        formState: { errors: errors3 },
        handleSubmit: handleSubmit3,
        setValue: setUboValue,
        trigger,
        clearErrors,
    } = useForm({
        mode: "onChange",
        defaultValues: {
            officials_1: uboData?.public_officials_1?.officials_1 || false,
            officials_2: uboData?.public_officials_2?.officials_2 || false,
            signature: uboData?.passport_information?.signature || null
        }
    });

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setUploadedImage(file);
            setShowImage(URL.createObjectURL(file));
            clearErrors("signature");
        } else {
            setUploadedImage(null);
            setShowImage(null);
        }
    };

    const removeImage = () => {
        setUploadedImage(null);
        setUboValue("signature", null);
        setShowImage(null)
    };

    const handleChange = (field, name, value, subField) => {
        setUboData((prevData) => ({
            ...prevData,
            [field]: {
                ...prevData[field],
                ...(subField
                    ? {
                        [subField]: {
                            ...prevData[field][subField],
                            [name]: value,
                        },
                    }
                    : { [name]: value }
                ),
            },
        }));
    }

    const onSubmit = (data) => {
        if (uploadedImage || showImage) {
            setUboData((prevDaata) => ({
                ...prevDaata,
                ubo: {
                    first_name: data.first_name,
                    last_name: data.last_name,
                    patronymic: data.patronymic || "",
                    dob: data.dob,
                    birth_place: data.birth_place,
                    citizenship: data.citizenship,
                    company_shareCapital: data.company_shareCapital,
                    domicile: data.domicile,
                    actual_address: data.actual_address,
                    source_wealth: data.source_wealth,
                    tax_residency: data.tax_residency,
                    tax_number: data.tax_number || "",
                    public_officials_1: {
                        officials_1: data.officials_1 || false,
                        specify_official_1: data.specify_official_1 || "",
                    },
                    public_officials_2: {
                        officials_2: data.officials_2 || false,
                        specify_official_2: data.specify_official_2 || "",
                        position_held: data.position_held,
                        period: data.period,
                        relationship: data.relationship,
                    },
                    phone_number: data.phone_number,
                    passport_information: {
                        document_name: data.document_name,
                        number: data.number,
                        issuing_body: data.issuing_body,
                        expiry_date: data.expiry_date,
                        business_activity: data.business_activity,
                        signature: uploadedImage ? uploadedImage : showImage,
                    }
                }
            }))
        }

        const values = new FormData();
        // values.append("individualStatus", 2)
        values.append("type", "ubo")
        values.append('first_name', data.first_name);
        values.append('last_name', data.last_name);
        values.append('patronymic', data.patronymic || "");
        values.append('dob', data.dob);
        values.append('birth_place', data.birth_place);
        values.append('citizenship', data.citizenship);
        values.append('company_shareCapital', data.company_shareCapital);
        values.append('domicile', data.domicile);
        values.append('actual_address', data.actual_address);
        values.append('source_wealth', data.source_wealth);
        values.append('tax_residency', data.tax_residency);
        values.append('tax_number', data.tax_number || "");
        values.append('officials_1', data.officials_1 || false);
        values.append('specify_official_1', data.specify_official_1 || "");
        values.append('officials_2', data.officials_2 || false);
        values.append('specify_official_2', data.specify_official_2 || "");
        values.append('position_held', data.position_held || "");
        values.append('period', data.period || "");
        values.append('relationship', data.relationship || "");
        values.append('phone_number', data.phone_number);
        values.append('document_name', data.document_name);
        values.append('number', data.number);
        values.append('issuing_body', data.issuing_body);
        values.append('expiry_date', data.expiry_date);
        values.append('business_activity', data.business_activity);

        if (uploadedImage) {
            values.append('signature', uploadedImage);
        } else {
            values.append('signature', showImage);
        }

        handleParentSubmit(values)
    }


    useEffect(() => {
        const getUBOData = async () => {
            try {
                let params = {
                    url: "user-individual-data",
                    method: "GET"
                }
                const resp = await makeApiRequest(params)
                if (resp.status) {
                    const response = resp.resp.ubo
                    setUboGetData(response)
                    setShowImage(response.passport_information.signature)
                } else {
                    setUboGetData({})
                    setShowImage(null)
                }
            } catch (error) {
                setUboGetData({})
            }
        }

        getUBOData()
    }, [])

    useEffect(() => {
        if (uboGetData) {
            setUboValue("first_name", uboGetData.first_name);
            setUboValue('last_name', uboGetData.last_name);
            setUboValue('patronymic', uboGetData.patronymic || "");
            setUboValue('dob', uboGetData.dob);
            setUboValue('birth_place', uboGetData.birth_place);
            setUboValue('citizenship', uboGetData.citizenship);
            setUboValue('company_shareCapital', uboGetData.company_shareCapital);
            setUboValue('domicile', uboGetData.domicile);
            setUboValue('actual_address', uboGetData.actual_address);
            setUboValue('source_wealth', uboGetData.source_wealth);
            setUboValue('tax_residency', uboGetData.tax_residency);
            setUboValue('tax_number', uboGetData.tax_number || "");
            setUboValue('officials_1', uboGetData?.public_officials_1?.officials_1 == true ? "true" : "false")
            setUboValue('specify_official_1', uboGetData?.public_officials_1?.specify_official_1 || "")
            setUboValue('officials_2', uboGetData?.public_officials_2?.officials_2 == true ? "true" : "false")
            setUboValue('specify_official_2', uboGetData?.public_officials?.specify_official_2 || "")
            setUboValue('position_held', uboGetData.position_held || "");
            setUboValue('period', uboGetData.period || "");
            setUboValue('relationship', uboGetData.relationship || "");
            setUboValue('phone_number', uboGetData.phone_number);
            setUboValue('document_name', uboGetData?.passport_information?.document_name);
            setUboValue('number', uboGetData?.passport_information?.number);
            setUboValue('issuing_body', uboGetData?.passport_information?.issuing_body);
            setUboValue('expiry_date', uboGetData?.passport_information?.expiry_date);
            setUboValue('business_activity', uboGetData?.passport_information?.business_activity);
            setUboValue('signature', uboGetData?.passport_information?.signature);
        }
    }, [uboGetData])

    return (
        <div >
            <form className='ubo' onSubmit={handleSubmit3(onSubmit)}>
                <div className='text-end mt-4'>
                    <button type="button" className="bef-b-login-1 w-auto" onClick={toggleLogin} >
                        Skip
                    </button>
                </div>
                <h4 className='text-center business-title text-uppercase py-4'>Ultimate beneficial owner (UBO) Information</h4>
                <p className='text-center mb-0 text-one'>(Beneficial owner means any natural person who ultimately owns or controls the legal entity through direct or indirect ownership or control over 25 % plus 1 (one) share or more of the shares or voting rights in that legal entity or any natural person who otherwise exercises control over the management of the legal entity)</p>
                <p className='text-center text-one'>(Name of the Company for which the listed below individual is the UBO)</p>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="mb-4 pt-2">
                            <label htmlFor="first_name" className="form-label">Name :</label>
                            <input
                                type="text"
                                className="form-control business-input-group-box"
                                id="first_name"
                                placeholder="First Name"
                                name='first_name'
                                {...register3("first_name", {
                                    required: "Enter Your First Name...",
                                    pattern: {
                                        value: /^[A-Za-z. ]*$/,
                                        message: "Invalid Name Format"
                                    },
                                    onChange: (e) => {
                                        handleChange("ubo", "first_name", e.target.value);
                                        clearErrors("first_name");
                                    },
                                    onBlur: async (e) => {
                                        await trigger(e.target.name)
                                    }
                                })}
                            />
                            {errors3.first_name && <div className="text-danger rq-msg">{errors3.first_name.message}</div>}
                        </div>
                        <div className="mb-4 pt-2">
                            <label htmlFor="last_name" className="form-label">Last name :</label>
                            <input
                                type="text"
                                className="form-control business-input-group-box"
                                id="last_name"
                                placeholder="Last name"
                                name='last_name'
                                {...register3("last_name", {
                                    required: "Enter Your Last Name...",
                                    pattern: {
                                        value: /^[A-Za-z. ]*$/,
                                        message: "Invalid Name Format"
                                    },
                                    onChange: (e) => {
                                        handleChange("ubo", "last_name", e.target.value);
                                        clearErrors("last_name");
                                    },
                                    onBlur: async (e) => {
                                        await trigger(e.target.name)
                                    }
                                })}
                            />
                            {errors3.last_name && <div className="text-danger rq-msg">{errors3.last_name.message}</div>}
                        </div>
                        <div className="mb-4 pt-2">
                            <label htmlFor="patronymic" className="form-label">Patronymic <span className='option-css'>(if applicable):</span></label>
                            <input
                                type="text"
                                className="form-control business-input-group-box"
                                id="patronymic"
                                placeholder="patronymic"
                                name='patronymic'
                                {...register3("patronymic", {
                                    onChange: (e) => {
                                        handleChange("ubo", "patronymic", e.target.value);
                                    },
                                })}
                            />
                            {errors3.patronymic && <div className="text-danger rq-msg">{errors3.patronymic.message}</div>}
                        </div>
                        <div className="mb-4 pt-2">
                            <label htmlFor="dob" className="form-label">Date of birth :</label>
                            <input
                                type="date"
                                className="form-control business-input-group-box"
                                id="dob"
                                placeholder="Date of birth"
                                name='dob'
                                {...register3("dob", {
                                    required: "Select Your Date of Birth...",
                                    onChange: (e) => {
                                        handleChange("ubo", "dob", e.target.value);
                                        clearErrors("dob");
                                    },
                                    onBlur: async (e) => {
                                        await trigger(e.target.name)
                                    }
                                })}
                            />
                            {errors3.dob && <div className="text-danger rq-msg">{errors3.dob.message}</div>}
                        </div>
                        <div className="mb-4 pt-2">
                            <label htmlFor="birth_place" className="form-label">Place of birth :</label>
                            <input
                                type="text"
                                className="form-control business-input-group-box"
                                id="birth_place"
                                placeholder="Place of birth"
                                name='birth_place'
                                {...register3("birth_place", {
                                    required: "Enter Your Place of Birth...",
                                    pattern: {
                                        value: /^[A-Za-z\s,\.]+$/,
                                        message: "Invalid Place of Birth Format"
                                    },
                                    onChange: (e) => {
                                        handleChange("ubo", "birth_place", e.target.value);
                                        clearErrors("birth_place");
                                    },
                                    onBlur: async (e) => {
                                        await trigger(e.target.name)
                                    }
                                })}
                            />
                            {errors3.birth_place && <div className="text-danger rq-msg">{errors3.birth_place.message}</div>}
                        </div>
                        <div className="mb-4 pt-2">
                            <label htmlFor="citizenship" className="form-label">Citizenship :</label>
                            <input
                                type="text"
                                className="form-control business-input-group-box"
                                id="citizenship"
                                placeholder="Citizenship"
                                name='citizenship'
                                {...register3("citizenship", {
                                    required: "Enter Your Citizenship...",
                                    pattern: {
                                        value: /^[A-Za-z\s\-]+$/,
                                        message: "Invalid Citizenship Format"
                                    },
                                    onChange: (e) => {
                                        handleChange("ubo", "citizenship", e.target.value);
                                        clearErrors("citizenship");
                                    },
                                    onBlur: async (e) => {
                                        await trigger(e.target.name)
                                    }
                                })}
                            />
                            {errors3.citizenship && <div className="text-danger rq-msg">{errors3.citizenship.message}</div>}
                        </div>
                        <div className="mb-4 pt-2">
                            <label htmlFor="company_shareCapital" className="form-label">Share in the share capital of the Company :</label>
                            <input
                                type="text"
                                className="form-control business-input-group-box"
                                id="company_shareCapital"
                                placeholder="share capital of the Company"
                                name='company_shareCapital'
                                {...register3("company_shareCapital", {
                                    required: "Enter Your Company Share Capital...",
                                    pattern: {
                                        value: /^[A-Za-z ]*$/,
                                        message: "Invalid Format"
                                    },
                                    onChange: (e) => {
                                        handleChange("ubo", "company_shareCapital", e.target.value);
                                        clearErrors("company_shareCapital");
                                    },
                                    onBlur: async (e) => {
                                        await trigger(e.target.name)
                                    }
                                })}
                            />
                            {errors3.company_shareCapital && <div className="text-danger rq-msg">{errors3.company_shareCapital.message}</div>}
                        </div>
                        <div className="mb-4 pt-2">
                            <label htmlFor="domicile" className="form-label">Domicile :</label>
                            <input
                                type="text"
                                className="form-control business-input-group-box"
                                id="domicile"
                                placeholder="Domicile"
                                name='domicile'
                                {...register3("domicile", {
                                    required: "Required...",
                                    pattern: {
                                        value: /^[A-Za-z. ]*$/,
                                        message: "Invalid Format"
                                    },
                                    onChange: (e) => {
                                        handleChange("ubo", "domicile", e.target.value);
                                        clearErrors("domicile");
                                    },
                                    onBlur: async (e) => {
                                        await trigger(e.target.name)
                                    }
                                })}
                            />
                            {errors3.domicile && <div className="text-danger rq-msg">{errors3.domicile.message}</div>}
                        </div>
                        <div className="mb-4 pt-2">
                            <label htmlFor="actual_address" className="form-label">Actual address :</label>
                            <textarea
                                type="text"
                                className="form-control business-input-group-box"
                                id="actual_address"
                                placeholder="Actual address"
                                name='actual_address'
                                {...register3("actual_address", {
                                    required: "Enter Your Address...",
                                    pattern: {
                                        value: /^[A-Za-z0-9.,#\-\/\s]+$/,
                                        message: "Invalid Address Format"
                                    },
                                    onChange: (e) => {
                                        handleChange("ubo", "actual_address", e.target.value);
                                        clearErrors("actual_address");
                                    },
                                    onBlur: async (e) => {
                                        await trigger(e.target.name)
                                    }
                                })}
                            />
                            {errors3.actual_address && <div className="text-danger rq-msg">{errors3.actual_address.message}</div>}
                        </div>
                        <div className="mb-4 pt-2">
                            <label htmlFor="source_wealth" className="form-label">Source of wealth :</label>
                            <input
                                type="text"
                                className="form-control business-input-group-box"
                                id="source_wealth"
                                placeholder="Source of wealth"
                                name='source_wealth'
                                {...register3("source_wealth", {
                                    required: "Source of Wealth Required...",
                                    pattern: {
                                        value: /^[A-Za-z. ]*$/,
                                        message: "Invalid Source of Wealth Format"
                                    },
                                    onChange: (e) => {
                                        handleChange("ubo", "source_wealth", e.target.value);
                                        clearErrors("source_wealth");
                                    },
                                    onBlur: async (e) => {
                                        await trigger(e.target.name)
                                    }
                                })}
                            />
                            {errors3.source_wealth && <div className="text-danger rq-msg">{errors3.source_wealth.message}</div>}
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='row'>
                        <div className="col-lg-6 col-12 mb-3">
                            <label className="mb-2  form-label">Country of Tax Residency :</label>
                            <input
                                type="text"
                                className="form-control business-input-group-box"
                                placeholder="Tax tax_residency"
                                name='tax_residency'
                                {...register3("tax_residency", {
                                    required: "Enter a Valid Country",
                                    pattern: {
                                        value: /^[A-Za-z. ]*$/,
                                        message: "Invalid Tax Residency Format"
                                    },
                                    onChange: (e) => {
                                        handleChange("ubo", "tax_residency", e.target.value);
                                        clearErrors("tax_residency");
                                    },
                                    onBlur: async (e) => {
                                        await trigger(e.target.name)
                                    }
                                })}
                            />
                            {errors3.tax_residency && <div className="text-danger rq-msg">{errors3.tax_residency.message}</div>}
                        </div>
                        <div className="col-lg-6 col-12 mb-3">
                            <label className="mb-2  form-label">Tax identification number <span className='option-css'>(if applicable):</span></label>
                            <input
                                type="text"
                                className="form-control business-input-group-box"
                                id='tax_number'
                                placeholder="TAX number"
                                name='tax_number'
                                {...register3("tax_number", {
                                    onChange: (e) => {
                                        handleChange("ubo", "tax_number", e.target.value);
                                        clearErrors("tax_number");
                                    },
                                })}
                            />
                        </div>
                    </div>
                </div>

                <div className="row mb-5">
                    <div className="col-lg-12">
                        <label htmlFor="sharehold" className="form-label">Are you  a public official/ Politically Exposed Person?</label>
                        <div className="d-flex align-items-center gap-lg-3 flex-wrap">
                            <div className="form-check me-3">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="officials_1"
                                    id="personYes1"
                                    value="true"
                                    {...register3('officials_1', {
                                        required: "Required...",
                                    })}
                                    onChange={() => setIsOfficial1(true)}
                                />
                                <label className="form-check-label" htmlFor="personYes1">
                                    Yes
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="officials_1"
                                    id="personNo1"
                                    value="false"
                                    {...register3('officials_1', {
                                        required: "Required...",
                                    })}
                                    onChange={() => setIsOfficial1(false)}
                                />
                                <label className="form-check-label" htmlFor="personNo1">
                                    No
                                </label>
                            </div>
                            <div class="d-flex align-items-center flex-wrap">
                                {isOfficial1 == true ? (
                                    <div>
                                        <div><label for="specifyOfficial" class="form-label me-2 mb-0">If “Yes,” please provide:</label></div>
                                        <div>
                                            <input
                                                type="text"
                                                class="form-control business-input-group-box"
                                                id="specifyOfficial"
                                                name='specify_official_1'
                                                {...register3('specify_official_1', {
                                                    required: "This field is required...",
                                                    onChange: (e) => {
                                                        handleChange("ubo", "public_officials_1", "specify_official_1", e.target.value)
                                                        clearErrors("specify_official_1");
                                                    },
                                                    onBlur: async (e) => {
                                                        await trigger(e.target.name)
                                                    }
                                                })}
                                            />
                                            {errors3.specify_official_1 && <div className="text-danger">{errors3.specify_official_1.message}</div>}
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                        {errors3.officials_1 && <div className="text-danger">{errors3.officials_1.message}</div>}
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-lg-12">
                        <label htmlFor="sharehold" className="form-label">Is any of your close family members/associates public official/ Politically Exposed Person?</label>
                        <div className="d-flex align-items-center gap-lg-3 flex-wrap mb-2">
                            <div className="form-check me-3">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="officials_2"
                                    id="yes2"
                                    value="true"
                                    {...register3('officials_2', {
                                        required: "Required...",
                                    })}
                                    onChange={() => setIsOfficial2(true)}
                                />
                                <label className="form-check-label" htmlFor="yes2">
                                    Yes
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="officials_2"
                                    id="no2"
                                    value="false"
                                    {...register3('officials_2', {
                                        required: "Required...",
                                    })}
                                    onChange={() => setIsOfficial2(false)}
                                />
                                <label className="form-check-label" htmlFor="no2">
                                    No
                                </label>
                            </div>
                            <div class="d-flex align-items-center flex-wrap">
                                {isOfficial2 == true ? (
                                    <div>
                                        <div><label for="specifyOfficial1" class="form-label me-2 mb-0">If “Yes,” please provide:</label></div>
                                        <div>
                                            <input
                                                type="text"
                                                class="form-control business-input-group-box"
                                                id="specifyOfficial1"
                                                name='specify_official_2'
                                                {...register3('specify_official_2', {
                                                    required: "This field is required...",
                                                    onChange: (e) => {
                                                        handleChange("ubo", "public_officials_2", "specify_official_2", e.target.value)
                                                        clearErrors("specify_official_2");
                                                    },
                                                    onBlur: async (e) => {
                                                        await trigger(e.target.name)
                                                    }
                                                })}
                                            />
                                            {errors3.specify_official_2 && <div className="text-danger">{errors3.specify_official_2.message}</div>}
                                        </div>
                                        <div className='row'>
                                            <div className="col-lg-4 col-12 mb-3">
                                                <label className="mb-2  form-label">Position held :</label>
                                                <input
                                                    type="text"
                                                    className="form-control business-input-group-box"
                                                    placeholder="Position"
                                                    id="position_held"
                                                    name='position_held'
                                                    {...register3('position_held', {
                                                        required: "Enter Your Position Held...",
                                                        pattern: {
                                                            value: /^[A-Za-z., ]*$/,
                                                            message: "Invalid Position Format"
                                                        },
                                                        onChange: (e) => {
                                                            handleChange("ubo", "public_officials_2", "position_held", e.target.value);
                                                            clearErrors("position_held");
                                                        },
                                                        onBlur: async (e) => {
                                                            await trigger(e.target.name)
                                                        }
                                                    })}
                                                />
                                                {errors3.position_held && <div className="text-danger">{errors3.position_held.message}</div>}
                                            </div>
                                            <div className="col-lg-4 col-12 mb-3">
                                                <label className="mb-2  form-label">Period :</label>
                                                <input
                                                    type="text"
                                                    className="form-control business-input-group-box"
                                                    placeholder="Period"
                                                    id="period"
                                                    name='period'
                                                    {...register3('period', {
                                                        required: "Enter the Period...",
                                                        onChange: (e) => {
                                                            handleChange("ubo", "public_officials_2", "period", e.target.value);
                                                            clearErrors("period");
                                                        },
                                                        onBlur: async (e) => {
                                                            await trigger(e.target.name)
                                                        }
                                                    })}
                                                />
                                                {errors3.period && <div className="text-danger">{errors3.period.message}</div>}
                                            </div>
                                            <div className="col-lg-4 col-12 mb-3">
                                                <label className="mb-2  form-label">Relationship with you :</label>
                                                <input
                                                    type="text"
                                                    className="form-control business-input-group-box"
                                                    placeholder="Relationship"
                                                    id="relationship"
                                                    name='relationship'
                                                    {...register3('relationship', {
                                                        required: "Required your Relationship...",
                                                        pattern: {
                                                            value: /^[A-Za-z\s.'-]*$/,
                                                            message: "Invalid Relationship Format"
                                                        },
                                                        onChange: (e) => {
                                                            handleChange("ubo", "public_officials_2", "relationship", e.target.value);
                                                            clearErrors("relationship");
                                                        },
                                                        onBlur: async (e) => {
                                                            await trigger(e.target.name)
                                                        }
                                                    })}
                                                />
                                                {errors3.relationship && <div className="text-danger">{errors3.relationship.message}</div>}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )}

                            </div>
                        </div>
                        {errors3.officials_2 && <div className="text-danger">{errors3.officials_2.message}</div>}

                    </div>
                </div>

                <div className='row'>
                    <div className="mb-4 pt-2">
                        <label htmlFor="phone_number" className="form-label">Contact phone number or faximile :</label>
                        <input
                            type="text"
                            className="form-control business-input-group-box"
                            placeholder="phone number"
                            id="phone_number"
                            name='phone_number'
                            {...register3('phone_number', {
                                required: "Enter Your Phone Number...",
                                pattern: {
                                    value: /^(\+?\d{1,4}[\s-]?)?(\(?\d{1,4}\)?[\s-]?)?[\d\s-#]{3,15}$/,
                                    message: "Invalid phone number format"
                                },
                                onChange: (e) => {
                                    handleChange("ubo", "phone_number", e.target.value);
                                    clearErrors("phone_number");
                                },
                                onBlur: async (e) => {
                                    await trigger(e.target.name)
                                }
                            })}
                        />
                        {errors3.phone_number && <div className="text-danger rq-msg">{errors3.phone_number.message}</div>}
                    </div>
                    <h4 className='business-subtitle'>ID or Passport information</h4>
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-12 mb-3 pt-2">
                            <label htmlFor="document_name" className="form-label">a. Name of the document  :</label>
                            <input
                                type="text"
                                className="form-control business-input-group-box"
                                placeholder="Name of the document"
                                id="document_name"
                                name='document_name'
                                {...register3('document_name', {
                                    required: "Enter the Exact name in Document...",
                                    pattern: {
                                        value: /^[A-Za-z ]*$/,
                                        message: "Invalid Name Format"
                                    },
                                    onChange: (e) => {
                                        handleChange("ubo", "passport_information", "document_name", e.target.value);
                                        clearErrors("document_name");
                                    },
                                    onBlur: async (e) => {
                                        await trigger(e.target.name)
                                    }
                                })}
                            />
                            {errors3.document_name && <div className="text-danger rq-msg">{errors3.document_name.message}</div>}
                        </div>
                        <div className="col-lg-6 col-12 mb-3 pt-2">
                            <label htmlFor="number" className="form-label">b.Document ID/Number :</label>
                            <input
                                type="text"
                                className="form-control business-input-group-box"
                                placeholder="Number"
                                id="number"
                                name='number'
                                {...register3('number', {
                                    required: "Enter the Number...",
                                    // pattern: {
                                    //     value: /^[+]?[0-9]*$/,
                                    //     message: "Invalid Number Format"
                                    // },
                                    onChange: (e) => {
                                        handleChange("ubo", "passport_information", "number", e.target.value);
                                        clearErrors("number");
                                    },
                                    onBlur: async (e) => {
                                        await trigger(e.target.name)
                                    }
                                })}
                            />
                            {errors3.number && <div className="text-danger rq-msg">{errors3.number.message}</div>}
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-12 mb-3 pt-2">
                            <label htmlFor="issuing_body" className="form-label">c. Issuing body :</label>
                            <input
                                type="text"
                                className="form-control business-input-group-box"
                                placeholder="Issuing body"
                                id="issuing_body"
                                name='issuing_body'
                                {...register3('issuing_body', {
                                    required: "Required your Issuing Body...",
                                    pattern: {
                                        value: /^[A-Za-z0-9 .-]*$/,
                                        message: "Invalid Issuing Body Format"
                                    },
                                    onChange: (e) => {
                                        handleChange("ubo", "passport_information", "issuing_body", e.target.value);
                                        clearErrors("issuing_body");
                                    },
                                    onBlur: async (e) => {
                                        await trigger(e.target.name)
                                    }
                                })}
                            />
                            {errors3.issuing_body && <div className="text-danger rq-msg">{errors3.issuing_body.message}</div>}
                        </div>
                        <div className="col-lg-6 col-12 mb-3 pt-2">
                            <label htmlFor="expiry_date" className="form-label">d.Validity (date of issue and date of expiration) :</label>
                            <input
                                type="date"
                                className="form-control business-input-group-box"
                                placeholder="Validity"
                                id="expiry_date"
                                name='expiry_date'
                                {...register3('expiry_date', {
                                    required: "Enter the Date of Issue and Expiry...",
                                    pattern: {
                                        value: /^[0-9\-\/]*$/,
                                        message: "Invalid Format: Only numbers, hyphens, and slashes are allowed"
                                    },
                                    onChange: (e) => {
                                        handleChange("ubo", "passport_information", "expiry_date", e.target.value);
                                        clearErrors("expiry_date");
                                    },
                                    onBlur: async (e) => {
                                        await trigger(e.target.name)
                                    }
                                })}
                            />
                            {errors3.expiry_date && <div className="text-danger rq-msg">{errors3.expiry_date.message}</div>}
                        </div>
                    </div>
                    <div className="mb-4 pt-2">
                        <label htmlFor="business_activity" className="form-label">Type of Business Activity :</label>
                        <input
                            type="text"
                            className="form-control business-input-group-box"
                            placeholder="Type of business activity"
                            id="business_activity"
                            name='business_activity'
                            {...register3('business_activity', {
                                required: 'Enter Your Business Activity..',
                                pattern: {
                                    value: /^[A-Za-z ]*$/,
                                    message: 'Invalid format.',
                                },
                                onChange: (e) => {
                                    handleChange("ubo", "passport_information", "business_activity", e.target.value);
                                    clearErrors("business_activity");
                                },
                                onBlur: async (e) => {
                                    await trigger(e.target.name)
                                }
                            })}
                        />
                        {errors3.business_activity && <div className="text-danger rq-msg">{errors3.business_activity.message}</div>}
                    </div>

                    <div className='mb-4 pt-2'>
                        <label htmlFor="sign" className="form-label">Signature :</label>
                        <div className='mb-4 pt-2'>
                            <input
                                type="file"
                                className="form-control business-input-group-box"
                                id="signature"
                                accept="image/*"
                                name="signature"
                                {...register3("signature", {
                                    required: showImage == null ? "Image is required" : "",
                                    validate: showImage === null
                                        ? {
                                            minSize: (value) => value[0].size < 2 * 1024 * 1024 || "File size must be less than 2 MB",
                                        }
                                        : {},
                                })}
                                onChange={(event) => {
                                    handleImageUpload(event);
                                }}
                            />

                            {showImage && (
                                <div className="my-2">
                                    <div className='form-label'>Uploaded Image:</div>
                                    <div className='d-flex align-items-start gap-3'>
                                        <img src={showImage} alt="Uploaded" style={{ maxWidth: '20%', height: 'auto', margin: "0px" }} />
                                        {/* <div><button type="button" className="btn btn-danger mt-2" onClick={removeImage}>Remove Image</button></div> */}
                                        <div><CiCircleRemove className='fs-3' onClick={removeImage} /></div>
                                    </div>
                                </div>
                            )}
                            {errors3.signature && <div className="text-danger rq-msg">{errors3.signature.message}
                            </div>}
                        </div>
                    </div>
                </div>

                <div className='d-flex align-items-center justify-content-between py-5'>
                    <div>
                        <button type="button" className="bef-b-login-1 w-auto me-2" onClick={handlePrevious}>
                            Previous
                        </button>
                    </div>
                    {
                        loggedin == true ? (
                            <button className='bef-b-login-1 w-auto' type="button">
                                <Spinner animation="border" />
                            </button>
                        ) : (
                            <button type="submit" className="bef-b-login-1 w-auto">
                                Submit
                            </button>
                        )
                    }
                </div>
            </form>
        </div>
    )
}

export default Ubo;