import React from 'react'

function Error() {
    return (
        <div className='error-page'>
            <div className='container-fluid' style={{paddingTop:"100px"}}>
                <div class="d-flex justify-content-center align-items-center ">
                    <div class="text-center">
                        <h1 class="text">404</h1>
                        <h3 class="">Look like you're lost</h3>
                        <p>the page you are looking for not available!</p>
                        <a href="/" class="">
                            <button class="bef-b-login-1 w-50">Go to Home</button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Error