import React, { useState } from 'react'
import { Link ,useNavigate} from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast, ToastContainer } from 'react-toastify'
import { makeApiRequest } from "../../config/AxiosService"

function Forgetpassword() {

    const Navigate = useNavigate();

    const formik = useFormik({
        initialValues: { email: "" },
        validationSchema: Yup.object({
            email: Yup.string().email("Invalid email").required("Email is required")
        }),
        onSubmit: async (values) => {
            const datas = new FormData()
            datas.append("email", values.email)
            try {
                let params = {
                    url: "user-forget-password",
                    method: "POST",
                    data: datas
                }
                const response = await makeApiRequest(params)
                if (response.status == true) {
                    localStorage.setItem("setToken", response.usertoken);
                    toast.success(response.message)
                    setTimeout(() => {
                        Navigate('/signin')
                    }, 2000);
                } else {
                    toast.error(response.message)
                }
            } catch (error) {
                toast.error("Something Went Wrong...")
            }
        }
    })

    return (
        <div className='forgetpassword'>
             <ToastContainer />
            <div className='bef-login-home-sec'>
                <section className='bef-signin-h-sec py-5'>
                    <div className='container'>
                        <div className='row justify-content-center'>
                            <div className='col-lg-6'>
                                <div className='card dash-card-1'>
                                    <div className='card-body'>
                                        <h5 class="dash-head-1 mb-2">Forget Password</h5>
                                        <p class="mb-0 dash-trans-txt-2 mb-4">Get an account and control your finances better with full control of your budgets and savings.</p>

                                        <div className='profile-section-one '>
                                            <form onSubmit={formik.handleSubmit}>
                                                <div class="mb-3">
                                                    <label for="email" class="form-label">Email</label>
                                                    <input
                                                        type="email"
                                                        class="form-control"
                                                        id="email"
                                                        placeholder="john@email.com"
                                                        value={formik.values.email}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.email && formik.errors.email ? (
                                                        <div className="text-danger rq-msg">{formik.errors.email}</div>
                                                    ) : null}
                                                </div>

                                                <div class="mt-4 mb-3">
                                                    <button class="d-send-btn-1 rounded-pill" >Submit</button>
                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Forgetpassword