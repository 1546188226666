import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { makeApiRequest } from '../../../config/AxiosService';
import { CiCircleRemove } from "react-icons/ci";
import { useNavigate } from 'react-router-dom';
import DataContext from "../../../context/DataContext"
import { Spinner } from 'react-bootstrap';


function Individual({ handlePrevious, loggedin, handleParentSubmit }) {
    const navigate = useNavigate();
    const { setIsLogedIn } = useContext(DataContext);
    const [individualData, setIndividualData] = useState({})
    const [isOfficial1, setIsOfficial1] = useState(null);
    const [isOfficial2, setIsOfficial2] = useState(null);
    const [uploadedImage, setUploadedImage] = useState(null);
    const [showImage, setShowImage] = useState(null);
    const [indivData, setIndivData] = useState({
        individuals: {
            surname: "",
            dob: "",
            birth_place: "",
            citizenship: "",
            actual_address: "",
            domicile: "",
            passport_information: {
                document_name: "",
                number: "",
                issuing_body: "",
                expiry_date: "",
                work_place: "",
                share_capital: "",
                tax_residency: "",
                tax_number: "",
                source_wealth: "",
                public_officials_1: {
                    officials_1: false,
                    specify_official_1: "",
                },
                public_officials_2: {
                    officials_2: false,
                    specify_official_2: "",
                    position_held: "",
                    period: "",
                    relationship: "",
                },
                shareHolder_nominee: false,
                phone_number: "",
                email: "",
                date: "",
                signature: null,
            }
        }
    });

    const toggleLogin = () => {
        setIsLogedIn(true);
        navigate('/');
    };

    const {
        register: register2,
        formState: { errors: errors2 },
        handleSubmit: handleSubmit2,
        setValue: setIndividualValue,
        trigger,
        clearErrors,
    } = useForm({
        mode: "onChange",
        defaultValues: {
            officials_1: individualData?.passport_information?.public_officials_1?.officials_1 || false,
            officials_2: individualData?.passport_information?.public_officials_2?.officials_2 || false,
            shareHolder_nominee: individualData?.passport_information?.shareHolder_nominee || false,
            signature: individualData?.passport_information?.signature || null
        }
    });

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setUploadedImage(file);
            setShowImage(URL.createObjectURL(file));
            clearErrors("signature");
        } else {
            setUploadedImage(null);
            setShowImage(null);
        }
    };

    const removeImage = () => {
        setUploadedImage(null);
        setIndividualValue("signature", null);
        setShowImage(null)
    };

    const handleChange = (field, name, value, subField) => {
        setIndivData((prevFormData) => ({
            ...prevFormData,
            [field]: {
                ...prevFormData[field],
                [name]: value,
                ...(subField && {
                    [subField]: {
                        ...prevFormData[field][subField],
                        [name]: value
                    }
                })
            },
        }));
    };

    const onSubmit = (data) => {
        if (uploadedImage || showImage) {
            setIndivData((prevFormData) => ({
                ...prevFormData,
                individuals: {
                    surname: data.surname,
                    dob: data.dob,
                    birth_place: data.birth_place,
                    citizenship: data.citizenship,
                    actual_address: data.actual_address,
                    domicile: data.domicile,
                    passport_information: {
                        document_name: data.document_name,
                        number: data.number,
                        issuing_body: data.issuing_body,
                        expiry_date: data.expiry_date,
                        work_place: data.work_place,
                        share_capital: data.share_capital || "",
                        tax_residency: data.tax_residency,
                        tax_number: data.tax_number || "",
                        source_wealth: data.source_wealth,
                        public_officials_1: {
                            officials_1: data.officials_1 || false,
                            specify_official_1: data.specify_official_1 || "",
                        },
                        public_officials_2: {
                            officials_2: data.officials_2 || false,
                            specify_official_2: data.specify_official_2 || "",
                            position_held: data.position_held || "",
                            period: data.period || "",
                            relationship: data.relationship || "",
                        },
                        shareHolder_nominee: data.shareHolder_nominee || false,
                        phone_number: data.phone_number,
                        email: data.email,
                        date: data.date,
                        signature: uploadedImage ? uploadedImage : showImage,
                    }
                }
            }));
        }
        const values = new FormData();
        // values.append("individualStatus", 1)
        values.append("type", "individuals")
        values.append('surname', data.surname);
        values.append('dob', data.dob);
        values.append('birth_place', data.birth_place);
        values.append('citizenship', data.citizenship);
        values.append('actual_address', data.actual_address);
        values.append('domicile', data.domicile);
        values.append('document_name', data.document_name);
        values.append('number', data.number);
        values.append('issuing_body', data.issuing_body);
        values.append('expiry_date', data.expiry_date);
        values.append('work_place', data.work_place);
        values.append('share_capital', data.share_capital || "");
        values.append('tax_residency', data.tax_residency);
        values.append('tax_number', data.tax_number || "");
        values.append('source_wealth', data.source_wealth);
        values.append('officials_1', data.officials_1 || false);
        values.append('specify_official_1', data.specify_official_1 || "");
        values.append('officials_2', data.officials_2 || false);
        values.append('specify_official_2', data.specify_official_2 || "");
        values.append('position_held', data.position_held || "");
        values.append('period', data.period || "");
        values.append('relationship', data.relationship || "");
        values.append('shareHolder_nominee', data.shareHolder_nominee || false);
        values.append('phone_number', data.phone_number);
        values.append('email', data.email);
        values.append('date', data.date);

        if (uploadedImage) {
            values.append('signature', uploadedImage);
        } else {
            values.append('signature', showImage);
        }
        handleParentSubmit(values);
    };


    useEffect(() => {
        const getLegalData = async () => {
            try {
                let params = {
                    url: "user-individual-data",
                    method: "GET"
                }
                const resp = await makeApiRequest(params)
                if (resp.status) {
                    const response = resp.resp.individuals
                    setIndividualData(response)
                    setShowImage(response.passport_information.signature)
                } else {
                    setIndividualData({})
                    setShowImage(null)
                }
            } catch (error) {
                setIndividualData({})
            }
        }

        getLegalData()
    }, [])

    useEffect(() => {
        if (individualData) {
            setIndividualValue("surname", individualData.surname)
            setIndividualValue("dob", individualData.dob)
            setIndividualValue("birth_place", individualData.birth_place)
            setIndividualValue("citizenship", individualData.citizenship)
            setIndividualValue("actual_address", individualData.actual_address)
            setIndividualValue("domicile", individualData.domicile)
            setIndividualValue("document_name", individualData?.passport_information?.document_name)
            setIndividualValue("number", individualData?.passport_information?.number)
            setIndividualValue("issuing_body", individualData?.passport_information?.issuing_body)
            setIndividualValue("expiry_date", individualData?.passport_information?.expiry_date)
            setIndividualValue("work_place", individualData?.passport_information?.work_place)
            setIndividualValue("share_capital", individualData?.passport_information?.share_capital || "")
            setIndividualValue("tax_residency", individualData?.passport_information?.tax_residency)
            setIndividualValue("tax_number", individualData?.passport_information?.tax_number || "")
            setIndividualValue("source_wealth", individualData?.passport_information?.source_wealth)
            setIndividualValue("officials_1", individualData?.passport_information?.public_officials_1?.officials_1 == true ? "true" : "false")
            setIndividualValue("specify_official_1", individualData?.passport_information?.public_officials_1?.specify_official_1)
            setIndividualValue("officials_2", individualData?.passport_information?.public_officials_2?.officials_2 == true ? "true" : "false")
            setIndividualValue("specify_official_2", individualData?.passport_information?.public_officials_2?.specify_official_2)
            setIndividualValue("position_held", individualData?.passport_information?.public_officials_2?.position_held)
            setIndividualValue("period", individualData?.passport_information?.public_officials_2?.period)
            setIndividualValue("relationship", individualData?.passport_information?.public_officials_2?.relationship)
            setIndividualValue("shareHolder_nominee", individualData?.passport_information?.shareHolder_nominee == true ? "true" : "false")
            setIndividualValue("phone_number", individualData?.passport_information?.phone_number)
            setIndividualValue("email", individualData?.passport_information?.email)
            setIndividualValue("date", individualData?.passport_information?.date)
            setIndividualValue("signature", individualData?.passport_information?.signature || false)
        }
    }, [individualData])

    return (
        <div >
            <form className='Individual' onSubmit={handleSubmit2(onSubmit)}>
                <div className='text-end mt-4'>
                    <button type="button" className="bef-b-login-1 w-auto" onClick={toggleLogin} >
                        Skip 
                    </button>
                </div>

                {/* <h4 className='mb-auto text-center business-title text-uppercase py-4'>Questionnaire for Shareholders and Administrative bodies</h4> */}
                <h4 className='mb-auto text-center business-title text-uppercase py-4'>(User Account Details)</h4>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="mb-4 pt-2">
                            <label htmlFor="surname" className="form-label">Full Name :</label>
                            <input
                                type="text"
                                className="form-control business-input-group-box"
                                id="surname"
                                placeholder="Enter your Full name"
                                name='surname'
                                {...register2("surname", {
                                    required: "Enter Your Surname...",
                                    pattern: {
                                        value: /^[A-Za-z. ]*$/,
                                        message: "Invalid Name Format"
                                    },
                                    onChange: (e) => {
                                        handleChange("individuals", "surname", e.target.value);
                                        clearErrors("surname");
                                    },
                                    onBlur: async (e) => {
                                        await trigger(e.target.name)
                                    }
                                })}
                            />
                            {errors2.surname && <div className="text-danger rq-msg">{errors2.surname.message}</div>}
                        </div>
                        <div className="mb-4 pt-2">
                            <label htmlFor="dob" className="form-label">Date of birth :</label>
                            <input
                                type="date"
                                className="form-control business-input-group-box"
                                id="dob"
                                placeholder="Date of birth"
                                name="dob"
                                {...register2("dob", {
                                    required: "Select Your Date of Birth...",
                                    onChange: (e) => {
                                        handleChange("individuals", "dob", e.target.value);
                                        clearErrors("dob");
                                    },
                                    onBlur: async (e) => {
                                        await trigger(e.target.name)
                                    }
                                })}
                            />
                            {errors2.dob && <div className="text-danger rq-msg">{errors2.dob.message}</div>}
                        </div>

                        <div className="mb-4 pt-2">
                            <label htmlFor="birth_place" className="form-label">Place of birth :</label>
                            <input
                                type="text"
                                className="form-control business-input-group-box"
                                id="birth_place"
                                placeholder="Place of birth"
                                name='birth_place'
                                {...register2("birth_place", {
                                    required: "Enter Your Place of Birth...",
                                    pattern: {
                                        value: /^[A-Za-z\s,\.]+$/,
                                        message: "Invalid Place of Birth Format"
                                    },
                                    onChange: (e) => {
                                        handleChange("individuals", "birth_place", e.target.value);
                                        clearErrors("birth_place");
                                    },
                                    onBlur: async (e) => {
                                        await trigger(e.target.name)
                                    }
                                })}
                            />
                            {errors2.birth_place && <div className="text-danger rq-msg">{errors2.birth_place.message}</div>}
                        </div>
                        <div className="mb-4 pt-2">
                            <label htmlFor="citizenship" className="form-label">Citizenship :</label>
                            <input
                                type="text"
                                className="form-control business-input-group-box"
                                id="citizenship"
                                placeholder="Citizenship"
                                name='citizenship'
                                {...register2("citizenship", {
                                    required: "Enter Your Citizenship...",
                                    pattern: {
                                        value: /^[A-Za-z\s\-]+$/,
                                        message: "Invalid Citizenship Format"
                                    },
                                    onChange: (e) => {
                                        handleChange("individuals", "citizenship", e.target.value);
                                        clearErrors("citizenship");
                                    },
                                    onBlur: async (e) => {
                                        await trigger(e.target.name)
                                    }
                                })}
                            />
                            {errors2.citizenship && <div className="text-danger rq-msg">{errors2.citizenship.message}</div>}
                        </div>
                        <div className="mb-4 pt-2">
                            <label htmlFor="actual_address" className="form-label">Actual address :</label>
                            <textarea
                                type="text"
                                className="form-control business-input-group-box"
                                id="actual_address"
                                placeholder="Actual address"
                                name='actual_address'
                                {...register2("actual_address", {
                                    required: "Enter Your Address...",
                                    onChange: (e) => {
                                        handleChange("individuals", "actual_address", e.target.value);
                                        clearErrors("actual_address");
                                    },
                                    onBlur: async (e) => {
                                        await trigger(e.target.name)
                                    }
                                })}
                            />
                            {errors2.actual_address && <div className="text-danger rq-msg">{errors2.actual_address.message}</div>}
                        </div>
                        <div className="mb-4 pt-2">
                            <label htmlFor="domicile" className="form-label">Domicile :</label>
                            <input
                                type="text"
                                className="form-control business-input-group-box"
                                id="domicile"
                                placeholder="Enter your Country of Residency"
                                name='domicile'
                                {...register2("domicile", {
                                    required: "Enter a Valid Country...",
                                    pattern: {
                                        value: /^[A-Za-z. ]*$/,
                                        message: "Invalid Format"
                                    },
                                    onChange: (e) => {
                                        handleChange("individuals", "domicile", e.target.value);
                                        clearErrors("domicile");
                                    },
                                    onBlur: async (e) => {
                                        await trigger(e.target.name)
                                    }
                                })}
                            />
                            {errors2.domicile && <div className="text-danger rq-msg">{errors2.domicile.message}</div>}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <h4 className='business-subtitle'>ID or Passport information</h4>
                        <div className="mb-4 pt-2">
                            <label htmlFor="document_name" className="form-label">a. Name of the document :</label>
                            <input
                                type="text"
                                className="form-control business-input-group-box"
                                id="document_name"
                                placeholder="Enter the exact name as per the Document"
                                name='document_name'
                                {...register2("document_name", {
                                    required: "This information is required...",
                                    pattern: {
                                        value: /^[A-Za-z ]*$/,
                                        message: "Invalid Name Format"
                                    },
                                    onChange: (e) => {
                                        handleChange("individuals", "passport_information", "document_name", e.target.value);
                                        clearErrors("document_name");
                                    },
                                    onBlur: async (e) => {
                                        await trigger(e.target.name)
                                    }
                                })}
                            />
                            {errors2.document_name && <div className="text-danger rq-msg">{errors2.document_name.message}</div>}
                        </div>
                        <div className="mb-4 pt-2">
                            <label htmlFor="number" className="form-label">b. Document ID/Number :</label>
                            <input
                                type="text"
                                className="form-control business-input-group-box"
                                id="number"
                                placeholder="Number"
                                name='number'
                                {...register2("number", {
                                    required: "This information is required...",
                                    // pattern: {
                                    //     value: /^[+]?[0-9]*$/,
                                    //     message: "Invalid Number Format"
                                    // },
                                    onChange: (e) => {
                                        handleChange("individuals", "passport_information", "number", e.target.value);
                                        clearErrors("number");
                                    },
                                    onBlur: async (e) => {
                                        await trigger(e.target.name)
                                    }
                                })}
                            />
                            {errors2.number && <div className="text-danger rq-msg">{errors2.number.message}</div>}
                        </div>
                        <div className="mb-4 pt-2">
                            <label htmlFor="issuing_body" className="form-label">c. Issuing body :</label>
                            <input
                                type="text"
                                className="form-control business-input-group-box"
                                id="issuing_body"
                                placeholder="Issuing body"
                                name='issuing_body'
                                {...register2("issuing_body", {
                                    required: "This information is required...",
                                    pattern: {
                                        value: /^[A-Za-z0-9 .-]*$/,
                                        message: "Invalid Issuing Body Format"
                                    },
                                    onChange: (e) => {
                                        handleChange("individuals", "passport_information", "issuing_body", e.target.value);
                                        clearErrors("issuing_body");
                                    },
                                    onBlur: async (e) => {
                                        await trigger(e.target.name)
                                    }
                                })}
                            />
                            {errors2.issuing_body && <div className="text-danger rq-msg">{errors2.issuing_body.message}</div>}
                        </div>
                        <div className="mb-4 pt-2">
                            <label htmlFor="expiry_date" className="form-label">d.Validity (date of issue and date of expiration) :</label>
                            <input
                                type="date"
                                className="form-control business-input-group-box"
                                id="expiry_date"
                                placeholder="Validity"
                                name='expiry_date'
                                {...register2("expiry_date", {
                                    required: "This information is required...",
                                    pattern: {
                                        value: /^[0-9\-\/]*$/,
                                        message: "Invalid Format: Only numbers, hyphens, and slashes are allowed"
                                    },
                                    onChange: (e) => {
                                        handleChange("individuals", "passport_information", "expiry_date", e.target.value);
                                        clearErrors("expiry_date");
                                    },
                                    onBlur: async (e) => {
                                        await trigger(e.target.name)
                                    }
                                })}
                            />
                            {errors2.expiry_date && <div className="text-danger rq-msg">{errors2.expiry_date.message}</div>}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="mb-4 pt-2">
                            <label htmlFor="work_place" className="form-label">Place of work, title :</label>
                            <input
                                type="text"
                                className="form-control business-input-group-box"
                                id="work_place"
                                placeholder="Place of work, title"
                                name='work_place'
                                {...register2("work_place", {
                                    required: "This information is required...",
                                    pattern: {
                                        value: /^[A-Za-z ]*$/,
                                        message: "Invalid Name Format"
                                    },
                                    onChange: (e) => {
                                        handleChange("individuals", "passport_information", "work_place", e.target.value);
                                        clearErrors("work_place");
                                    },
                                    onBlur: async (e) => {
                                        await trigger(e.target.name)
                                    }
                                })}
                            />
                            {errors2.work_place && <div className="text-danger rq-msg">{errors2.work_place.message}</div>}
                        </div>
                        <div className="mb-4 pt-2">
                            <label htmlFor="share_capital" className="form-label">Share in the share capital of the Company <span className='option-css'>(for shareholders only)</span></label>
                            <input
                                type="text"
                                className="form-control business-input-group-box"
                                id="share_capital"
                                placeholder="enter"
                                name='share_capital'
                                {...register2("share_capital", {
                                    onChange: (e) => {
                                        handleChange("individuals", "passport_information", "share_capital", e.target.value);
                                        clearErrors("share_capital");
                                    },
                                    onBlur: async (e) => {
                                        await trigger(e.target.name)
                                    }
                                })}
                            />
                        </div>

                        <div className='row'>
                            <div className="col-lg-6 col-12 mb-3">
                                <label className="mb-2  form-label">Country of Tax Residency :</label>
                                <input
                                    type="text"
                                    className="form-control business-input-group-box"
                                    placeholder="Tax Residency"
                                    id='tax_residency'
                                    name='tax_residency'
                                    {...register2("tax_residency", {
                                        required: "This information is required...",
                                        pattern: {
                                            value: /^[A-Za-z. ]*$/,
                                            message: "Enter a Valid Country"
                                        },
                                        onChange: (e) => {
                                            handleChange("individuals", "passport_information", "tax_residency", e.target.value);
                                            clearErrors("tax_residency");
                                        },
                                        onBlur: async (e) => {
                                            await trigger(e.target.name)
                                        }
                                    })}
                                />
                                {errors2.tax_residency && <div className="text-danger rq-msg">{errors2.tax_residency.message}</div>}
                            </div>
                            <div className="col-lg-6 col-12 mb-3">
                                <label className="mb-2  form-label">Tax identification number <span className='option-css'>(if applicable):</span></label>
                                <input
                                    type="text"
                                    className="form-control business-input-group-box"
                                    placeholder="TAX number"
                                    id='tax_number'
                                    name='tax_number'
                                    {...register2("tax_number", {
                                        onChange: (e) => {
                                            handleChange("individuals", "passport_information", "tax_number", e.target.value);
                                            clearErrors("tax_number");
                                        },
                                    })}
                                />
                            </div>
                        </div>
                        <div className="mb-4 pt-2">
                            <label htmlFor="source_wealth" className="form-label">Source of wealth :</label>
                            <input
                                type="text"
                                className="form-control business-input-group-box"
                                id="source_wealth"
                                placeholder="Source of wealth"
                                name='source_wealth'
                                {...register2("source_wealth", {
                                    required: "This information is required...",
                                    pattern: {
                                        value: /^[A-Za-z. ]*$/,
                                        message: "Invalid Source of Wealth Format"
                                    },
                                    onChange: (e) => {
                                        handleChange("individuals", "passport_information", "source_wealth", e.target.value);
                                        clearErrors("source_wealth");
                                    },
                                    onBlur: async (e) => {
                                        await trigger(e.target.name)
                                    }
                                })}
                            />
                            {errors2.source_wealth && <div className="text-danger rq-msg">{errors2.source_wealth.message}</div>}
                        </div>

                    </div>
                </div>

                <div className="row mb-5">
                    <div className="col-lg-12">
                        <label htmlFor="sharehold" className="form-label">Are you  a public official/ Politically Exposed Person?</label>
                        <div className="d-flex align-items-center gap-lg-3 flex-wrap">
                            <div className="form-check me-3">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="officials_1"
                                    id="personYes"
                                    value="true"
                                    {...register2('officials_1', {
                                        required: "Required...",
                                    })}
                                    onChange={() => setIsOfficial1(true)}
                                />
                                <label className="form-check-label" htmlFor="personYes">
                                    Yes
                                </label>
                            </div>

                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="officials_1"
                                    id="personNo"
                                    value="false"
                                    {...register2('officials_1', {
                                        required: "Required...",
                                    })}
                                    onChange={() => setIsOfficial1(false)}
                                />
                                <label className="form-check-label" htmlFor="personNo">
                                    No
                                </label>
                            </div>
                            <div className="d-flex align-items-center flex-wrap">
                                {isOfficial1 == true ? (
                                    <div>
                                        <div>
                                            <label htmlFor="specifyOfficial" className="form-label me-2 mb-0">If “Yes,” please provide:</label>
                                        </div>
                                        <div>
                                            <input
                                                type="text"
                                                className="form-control business-input-group-box"
                                                id="specifyOfficial"
                                                name='specify_official_1'
                                                {...register2('specify_official_1', {
                                                    required: "This field is required...",
                                                    onBlur: async (e) => {
                                                        await trigger(e.target.name)
                                                    }
                                                })}
                                            />
                                            {errors2.specify_official_1 && <div className="text-danger">{errors2.specify_official_1.message}</div>}
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )}

                            </div>
                        </div>
                        {errors2.officials_1 && <div className="text-danger">{errors2.officials_1.message}</div>}
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-lg-12">
                        <label htmlFor="sharehold" className="form-label">Is any of your close family members/associates public official/ Politically Exposed Person?</label>
                        <div className="d-flex align-items-center gap-lg-3 flex-wrap mb-2">
                            <div className="form-check me-3">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="officials_2"
                                    id="yesone"
                                    value="true"
                                    {...register2('officials_2', {
                                        required: "Required...",
                                    })}
                                    onChange={() => setIsOfficial2(true)}
                                />
                                <label className="form-check-label" htmlFor="yesone">
                                    Yes
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="officials_2"
                                    id="noone"
                                    value="false"
                                    {...register2('officials_2', {
                                        required: "Required...",
                                    })}
                                    onChange={() => setIsOfficial2(false)}
                                />
                                <label className="form-check-label" htmlFor="noone">
                                    No
                                </label>
                            </div>
                            <div class="d-flex align-items-center flex-wrap">
                                {isOfficial2 == true ? (
                                    <div>
                                        <div><label for="specifyOfficial1" class="form-label me-2 mb-0">If “Yes,” please provide:</label></div>
                                        <div>
                                            <input
                                                type="text"
                                                className="form-control business-input-group-box"
                                                id="specifyOfficial1"
                                                name='specify_official_2'
                                                {...register2('specify_official_2', {
                                                    required: "This field is required.",
                                                    onChange: () => {
                                                        clearErrors("specify_official_2");
                                                    },
                                                    onBlur: async (e) => {
                                                        await trigger(e.target.name)
                                                    }
                                                })}
                                            />
                                            {errors2.specify_official_2 && <div className="text-danger">{errors2.specify_official_2.message}</div>}
                                        </div>
                                        <div className='row'>
                                            <div className="col-lg-4 col-12 mb-3">
                                                <label className="mb-2  form-label">Position held :</label>
                                                <input
                                                    type="text"
                                                    className="form-control business-input-group-box"
                                                    placeholder="Position"
                                                    id='position_held'
                                                    name='position_held'
                                                    {...register2("position_held", {
                                                        required: "Enter Your Position Held...",
                                                        pattern: {
                                                            value: /^[A-Za-z., ]*$/,
                                                            message: "Invalid Position Format"
                                                        },
                                                        onChange: (e) => {
                                                            handleChange("individuals", "passport_information", "position_held", e.target.value);
                                                            clearErrors("position_held");
                                                        },
                                                        onBlur: async (e) => {
                                                            await trigger(e.target.name)
                                                        }
                                                    })}
                                                />
                                                {errors2.position_held && <div className="text-danger rq-msg">{errors2.position_held.message}</div>}
                                            </div>
                                            <div className="col-lg-4 col-12 mb-3">
                                                <label className="mb-2  form-label">Period :</label>
                                                <input
                                                    type="text"
                                                    className="form-control business-input-group-box"
                                                    placeholder="Period"
                                                    id='period'
                                                    name='period'
                                                    {...register2("period", {
                                                        required: "Enter the Period...",
                                                        onChange: (e) => {
                                                            handleChange("individuals", "passport_information", "period", e.target.value);
                                                            clearErrors("period");
                                                        },
                                                        onBlur: async (e) => {
                                                            await trigger(e.target.name)
                                                        }
                                                    })}
                                                />
                                                {errors2.period && <div className="text-danger rq-msg">{errors2.period.message}</div>}
                                            </div>
                                            <div className="col-lg-4 col-12 mb-3">
                                                <label className="mb-2  form-label">Relationship with you :</label>
                                                <input
                                                    type="text"
                                                    className="form-control business-input-group-box"
                                                    placeholder="Relationship"
                                                    id='relationship'
                                                    name='relationship'
                                                    {...register2("relationship", {
                                                        required: "Required your Relationship...",
                                                        pattern: {
                                                            value: /^[A-Za-z\s.'-]*$/,
                                                            message: "Invalid Relationship Format"
                                                        },
                                                        onChange: (e) => {
                                                            handleChange("individuals", "passport_information", "relationship", e.target.value);
                                                            clearErrors("relationship");
                                                        },
                                                        onBlur: async (e) => {
                                                            await trigger(e.target.name)
                                                        }
                                                    })}
                                                />
                                                {errors2.relationship && <div className="text-danger rq-msg">{errors2.relationship.message}</div>}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )}

                            </div>
                        </div>
                        {errors2.officials_2 && <div className="text-danger">{errors2.officials_2.message}</div>}




                    </div>
                </div>

                <div className='row mb-4'>
                    <label htmlFor="sharehold" className="form-label">Is the Shareholder acting as a nominee?</label>
                    <div className="d-flex mb-4">
                        <div className="form-check me-3">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="shareHolder_nominee"
                                id="shareyes"
                                value="true"
                                {...register2('shareHolder_nominee', {
                                    required: "Required..."
                                })}
                            />
                            <label className="form-check-label" htmlFor="shareyes">
                                Yes
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="shareHolder_nominee"
                                id="shareno"
                                value="false"
                                {...register2('shareHolder_nominee', {
                                    required: "Required..."
                                })}
                            />
                            <label className="form-check-label" htmlFor="shareno">
                                No
                            </label>
                        </div>
                    </div>
                    {errors2.shareHolder_nominee && <div className="text-danger">{errors2.shareHolder_nominee.message}</div>}

                    <div className="mb-4 pt-2">
                        <label htmlFor="phone_number" className="form-label">Contact phone number or faximile :</label>
                        <input
                            type="text"
                            className="form-control business-input-group-box"
                            id="phone_number"
                            placeholder="phone number"
                            name='phone_number'
                            {...register2("phone_number", {
                                required: "Enter Your Phone Number...",
                                pattern: {
                                    value: /^(\+?\d{1,4}[\s-]?)?(\(?\d{1,4}\)?[\s-]?)?[\d\s-#]{3,15}$/,
                                    message: "Invalid phone number format"
                                },
                                onChange: (e) => {
                                    handleChange("individuals", "passport_information", "phone_number", e.target.value);
                                    clearErrors("phone_number");
                                },
                                onBlur: async (e) => {
                                    await trigger(e.target.name)
                                }
                            })}
                        />
                        {errors2.phone_number && <div className="text-danger rq-msg">{errors2.phone_number.message}</div>}
                    </div>
                    <div className="mb-4 pt-2">
                        <label htmlFor="email" className="form-label">Email :</label>
                        <input
                            type="email"
                            className="form-control business-input-group-box"
                            id="email"
                            placeholder="Email"
                            name='email'
                            {...register2("email", {
                                required: "Enter Your Email...",
                                pattern: {
                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                    message: "Invalid Email Format"
                                },
                                onChange: (e) => {
                                    handleChange("individuals", "passport_information", "email", e.target.value);
                                    clearErrors("email");
                                },
                                onBlur: async (e) => {
                                    await trigger(e.target.name)
                                }
                            })}
                        />
                        {errors2.email && <div className="text-danger rq-msg">{errors2.email.message}</div>}
                    </div>
                    <div className="mb-4 pt-2">
                        <label htmlFor="date" className="form-label">Date :</label>
                        <input
                            type="date"
                            className="form-control business-input-group-box"
                            id="date"
                            placeholder="Date"
                            name='date'
                            {...register2("date", {
                                required: "Select the Date...",
                                onChange: (e) => {
                                    handleChange("individuals", "passport_information", "date", e.target.value);
                                    clearErrors("date");
                                },
                                onBlur: async (e) => {
                                    await trigger(e.target.name)
                                }
                            })}
                        />
                        {errors2.date && <div className="text-danger rq-msg">{errors2.date.message}</div>}
                    </div>
                    <div className='mb-4 pt-2'>
                        <label htmlFor="signature" className="form-label">Signature :</label>
                        <input
                            type="file"
                            className="form-control business-input-group-box"
                            id="signature"
                            accept="image/*"
                            name="signature"
                            {...register2("signature", {
                                required: showImage == null ? "Image is required" : "",
                                validate: showImage === null
                                    ? {
                                        minSize: (value) => value[0].size < 2 * 1024 * 1024 || "File size must be less than 2 MB",
                                    }
                                    : {},
                            })}
                            onChange={(event) => {
                                handleImageUpload(event);
                            }}
                        />

                        {showImage && (
                            <div className="mt-2">
                                <div className='form-label'>Uploaded Image:</div>
                                <div className='d-flex align-items-start gap-3'>
                                    <img src={showImage} alt="Uploaded" style={{ maxWidth: '20%', height: 'auto', margin: "0px" }} />
                                    {/* <div><button type="button" className="btn btn-danger" onClick={removeImage}>Remove Image</button></div> */}
                                    <div><CiCircleRemove className='fs-3' onClick={removeImage} /></div>
                                </div>
                            </div>
                        )}
                        {errors2.signature && <div className="text-danger rq-msg">{errors2.signature.message}
                        </div>}
                    </div>
                </div>

                <div className='d-flex align-items-center justify-content-end py-lg-5 pb-3'>
                    {/* <div><button type="button" className="bef-b-login-1 w-auto me-2" onClick={handlePrevious}>
                        Previous
                    </button></div> */}
                    {
                        loggedin == true ? (
                            <button className='bef-b-login-1 w-auto px-5 py-1' type="button">
                                <Spinner animation="border" />
                            </button>
                        ) : (
                            <div><button type="submit" className="bef-b-login-1 w-auto">
                                Save & Next
                            </button></div>
                        )
                    }

                </div>
            </form>
        </div>
    )
}

export default Individual