import React, { useState, useRef } from 'react';
// import './assets/css/var.css';
import { FaArrowAltCircleDown } from "react-icons/fa";
import { useFormik } from "formik"
import * as Yup from "yup"
import { makeApiRequest } from "../../config/AxiosService"
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

function Business() {
  const Navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [filePreviews, setFilePreviews] = useState({});
  const fileInputRef = useRef(null);

  const formik = useFormik({
    initialValues: {
      firstname: "",
      abbreviatename: "",
      foreignlanguagesname: "",
      Date: "",
      Registration_number: "",
      Registration_authority: "",
      placeofregistration: "",
      legal_address: "",
      postal_address: "",
      emailURL: "",
      licensein_formation: {
        typeof_Activity: "",
        licenseNo: "",
        dateofIssue: "",
        issuing_Authority: "",
        validity: "",
        country_taxresidency: "",
        taxnumber: "",
      },
      sharecaptial: {
        registered_sharecapital: "",
        paid_sharecaptial: "",
        numberofpersonnel: "",
        legalproceedingsCompany: {
          bankruptcy: false,
          bankruptcyspecify: ""
        },
        financialobligations: {
          lackoffunds_bankaccounts: false,
          financialspecify: ""
        }
      },

      listoftheBoardofDirectors: {
        DirectorFirstnominee: "",
        Directoractingnominee1: false,
        DirectorSecondnominee: "",
        Directoractingnominee2: false,
        DirectorThirdnominee: "",
        Directoractingnominee3: false,
        DirectorFourthnominee: "",
        Directoractingnominee4: false,
        DirectorFifthnominee: "",
        Directoractingnominee5: false
      },
      NameoftheCFO: "",
      details_Beneficialowner: "",
      detailsofShareholders: {
        firstshareholder: "",
        percentageofsharesheld1: "",
        Shareholdernomuinee1: false,
        secondshareholder: "",
        percentageofsharesheld2: "",
        Shareholdernomuinee2: false,
        thirdshareholder: "",
        percentageofsharesheld3: "",
        Shareholdernomuinee3: false,
        fourthshareholder: "",
        percentageofsharesheld4: "",
        Shareholdernomuinee4: false
      },
      Types_activity: "",
      Business_activity: "",
      financial_legislation: {
        legislation_framework: true,
        legislationspecify: ""
      },
      business_activityarea: "",
      averageamount_Transactions: "",
      website_Company: "",
      competitors_Company: "",
      generalAMLpolicies: {
        Amlpolicies1: {
          regulatory_authority: "No",
          moneylaundering_prevention: "",
          operating_licensenumber: ""
        },
        Amlpolicies2: "No",
        Amlpolicies3: {
          AML_CTFprogram1: "No",
          Aml_CTFprogram2: {
            CompleteName1: "",
            Position_Title1: "",
            Mailing_Address1: "",
            Telephone_Number1: ""
          },
          Aml_CTFprogram3: "No",
          Aml_CTFprogram4: {
            CompleteName2: "",
            Position_Title2: "",
            Mailing_Address2: "",
            Telephone_Number2: ""
          },
        },
        Amlpolicies4: "No",
        Amlpolicies5: {
          AMLprogram_Directors: "No",
          AMLprogram_DirectorsSpecify: ""
        },
        Amlpolicies6: "No",
        Amlpolicies7: "No",
        Amlpolicies8: "No",
        Amlpolicies9: {
          investigation_MLandCTF: "No",
          investigation_MLandCTFSpecify: ""
        },
        Amlpolicies10: {
          enforcement_action: "No",
          enforcement_actionAssessed: "No"
        },
        Amlpolicies11: {
          MLandCTF_crimes: "No",
          MLandCTF_crimesSpecify: ""
        },
        Amlpolicies12: "No",
        Amlpolicies13: "",
        Amlpolicies14: {
          prohibit_opening: "No",
          prohibit_openingSpecify: ""
        }
      },
      Documentation: [{
        Memorandum_document: null,
        Incorporation_document: null,
        Directors_document: null,
        goodstanding_document: null,
        Incumbency_document: null,
        Shareholders_document: null,
        Legalownership_document: null,
        Operatinglicense_document: null,
        DeclarationofTrust_document: null,
        TrustSettlement_document: null,
        OnboardingForm_document: null,
        Annualfinancial_document: null,
        Auditedfinancial_document: null,
        absenceof_document: null,
        IdentityCardorPassport_document: null,
        permanentaddress_document: null,
        Accountopening_document: null,
        AML_CTF_document: null,
        signature: null
      }],


    },



    validationSchema: Yup.object({
      firstname: Yup.string().required('First name is required'),
      Date: Yup.string().required('Date is required'),
      Registration_number: Yup.string().required('Registration number is required'),
      Registration_authority: Yup.string().required('Registration authority is required'),
      placeofregistration: Yup.string().required('Place of registration is required'),
      legal_address: Yup.string().required('Legal address is required'),
      postal_address: Yup.string().required('Postal address is required'),
      emailURL: Yup.string()
        .required('Email or URL is required')
        .test('is-valid-email-or-url', 'Invalid email or URL format', value => {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          const urlRegex = /^(https:\/\/|www\.)[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

          return emailRegex.test(value) || urlRegex.test(value);
        }),
      sharecaptial: Yup.object({
        registered_sharecapital: Yup.string().matches(/^\d+$/, 'Only numbers are allowed').required('Registered share capital is required'),
        paid_sharecaptial: Yup.string().matches(/^\d+$/, 'Only numbers are allowed').required('Paid share capital is required'),
        numberofpersonnel: Yup.string().matches(/^\d+$/, 'Only numbers are allowed').required('Number of personnel is required'),
        // .min(1, 'At least 1 personnel is required'),
        // legalproceedingsCompany: Yup.object({
        //   bankruptcy: Yup.boolean().nullable().required('Please select an Option'),
        // bankruptcyspecify: Yup.string().required('Please specify bankruptcy details')

        // }),
        // financialobligations: Yup.object({
        //   lackoffunds_bankaccounts: Yup.boolean().nullable().required('Please select an Option'),
        //   financialspecify: Yup.string().required('Please specify financial obligations')
        // })
      }),
      // listoftheBoardofDirectors: Yup.object({
      //   Directoractingnominee1: Yup.boolean().nullable().required('Please select an Option'),
      //   Directoractingnominee2: Yup.boolean().nullable().required('Please select an Option'),
      //   Directoractingnominee3: Yup.boolean().nullable().required('Please select an Option'),
      //   Directoractingnominee4: Yup.boolean().nullable().required('Please select an Option'),
      //   Directoractingnominee5: Yup.boolean().nullable().required('Please select an Option')
      // }),
      NameoftheCFO: Yup.string().required('Name of the CFO is required'),
      details_Beneficialowner: Yup.string().required('Details of the Beneficial Owner are required'),
      // detailsofShareholders: Yup.object({
      //   percentageofsharesheld1: Yup.boolean().nullable().required('Please select an Option'),
      //   percentageofsharesheld2: Yup.boolean().nullable().required('Please select an Option'),
      //   percentageofsharesheld3: Yup.boolean().nullable().required('Please select an Option'),
      //   percentageofsharesheld4: Yup.boolean().nullable().required('Please select an Option'),
      // }),
      Types_activity: Yup.string().required('This information is required'),
      Business_activity: Yup.string().required('This information is required'),
      // financial_legislation: Yup.object({
      //   legislation_framework: Yup.boolean().nullable().required('Please select an Option'),
      //   legislationspecify: Yup.string().required('Please specify the legislation framework')
      // }),
      business_activityarea: Yup.string().required('This information is required'),
      averageamount_Transactions: Yup.string().required('This information is required'),
      //   .min(1, 'The amount must be greater than zero'),
      website_Company: Yup.string().required('This information is required'),
      competitors_Company: Yup.string().required('This information is required'),
      generalAMLpolicies: Yup.object({
        Amlpolicies13: Yup.string().required('This information is required'),
      }),
      Documentation: Yup.array().of(
        Yup.object({
          Memorandum_document: Yup.mixed().required('Memorandum_document is required')
            .test('fileFormat', 'Only JPG, PNG, PDF, or DOCX files are allowed', (value) => {
              return !value || ['image/jpeg', 'image/png', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(value.type);
            }),
          Incorporation_document: Yup.mixed().required('Incorporation_document is required')
            .test('fileFormat', 'Only JPG, PNG, PDF, and DOCX files are allowed', (value) => {
              return !value || ['image/jpeg', 'image/png', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(value.type);
            }),
          Directors_document: Yup.mixed().required('Directors_document is required')
            .test('fileFormat', 'Only JPG, PNG, PDF, and DOCX files are allowed', (value) => {
              return !value || ['image/jpeg', 'image/png', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(value.type);
            }),
          goodstanding_document: Yup.mixed().required('goodstanding_document is required')
            .test('fileFormat', 'Only JPG, PNG, PDF, and DOCX files are allowed', (value) => {
              return !value || ['image/jpeg', 'image/png', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(value.type);
            }),
          Incumbency_document: Yup.mixed().required('Incumbency_document is required')
            .test('fileFormat', 'Only JPG, PNG, PDF, and DOCX files are allowed', (value) => {
              return !value || ['image/jpeg', 'image/png', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(value.type);
            }),

          Shareholders_document: Yup.mixed().required('Shareholders_document is required')
            .test('fileFormat', 'Only JPG, PNG, PDF, and DOCX files are allowed', (value) => {
              return !value || ['image/jpeg', 'image/png', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(value.type);
            }),
          Legalownership_document: Yup.mixed().required('Legalownership_document is required')
            .test('fileFormat', 'Only JPG, PNG, PDF, and DOCX files are allowed', (value) => {
              return !value || ['image/jpeg', 'image/png', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(value.type);
            }),
          Operatinglicense_document: Yup.mixed().required('Operatinglicense_document is required')
            .test('fileFormat', 'Only JPG, PNG, PDF, and DOCX files are allowed', (value) => {
              return !value || ['image/jpeg', 'image/png', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(value.type);
            }),


          OnboardingForm_document: Yup.mixed().required('OnboardingForm_document is required')
            .test('fileFormat', 'Only JPG, PNG, PDF, and DOCX files are allowed', (value) => {
              return !value || ['image/jpeg', 'image/png', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(value.type);
            }),
          Annualfinancial_document: Yup.mixed().required('Annualfinancial_document is required')
            .test('fileFormat', 'Only JPG, PNG, PDF, and DOCX files are allowed', (value) => {
              return !value || ['image/jpeg', 'image/png', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(value.type);
            }),

          Auditedfinancial_document: Yup.mixed().required('Auditedfinancial_document is required')
            .test('fileFormat', 'Only JPG, PNG, PDF, and DOCX files are allowed', (value) => {
              return !value || ['image/jpeg', 'image/png', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(value.type);
            }),
          absenceof_document: Yup.mixed().required('absenceof_document is required')
            .test('fileFormat', 'Only JPG, PNG, PDF, and DOCX files are allowed', (value) => {
              return !value || ['image/jpeg', 'image/png', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(value.type);
            }),
          IdentityCardorPassport_document: Yup.mixed().required('IdentityCardorPassport_document is required')
            .test('fileFormat', 'Only JPG, PNG, PDF, and DOCX files are allowed', (value) => {
              return !value || ['image/jpeg', 'image/png', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(value.type);
            }),
          permanentaddress_document: Yup.mixed().required('permanentaddress_document is required')
            .test('fileFormat', 'Only JPG, PNG, PDF, and DOCX files are allowed', (value) => {
              return !value || ['image/jpeg', 'image/png', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(value.type);
            }),

          Accountopening_document: Yup.mixed().required('Accountopening_document is required')
            .test('fileFormat', 'Only JPG, PNG, PDF, and DOCX files are allowed', (value) => {
              return !value || ['image/jpeg', 'image/png', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(value.type);
            }),
          signature: Yup.mixed().required('signature is required')
            .test('fileFormat', 'Only JPG, PNG, PDF, and DOCX files are allowed', (value) => {
              return !value || ['image/jpeg', 'image/png', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(value.type);
            }),
        })
      ),

    }),

    onSubmit: async (values) => {
      // console.log('values--', values);
      const form = document.getElementById("businessform");
      const formData = new FormData(form);
      formData.append("type", "Bussiness")
      formData.append("email", localStorage.getItem("email"))
      formData.append("Memorandum_document", values.Documentation[0].Memorandum_document);
      formData.append("Incorporation_document", values.Documentation[0].Incorporation_document);
      formData.append("Directors_document", values.Documentation[0].Directors_document);
      formData.append("goodstanding_document", values.Documentation[0].goodstanding_document);
      formData.append("Incumbency_document", values.Documentation[0].Incumbency_document);
      formData.append("Shareholders_document", values.Documentation[0].Shareholders_document);
      formData.append("Legalownership_document", values.Documentation[0].Legalownership_document);
      formData.append("Operatinglicense_document", values.Documentation[0].Operatinglicense_document);
      formData.append("DeclarationofTrust_document", values.Documentation[0].DeclarationofTrust_document);
      formData.append("TrustSettlement_document", values.Documentation[0].TrustSettlement_document);
      formData.append("OnboardingForm_document", values.Documentation[0].OnboardingForm_document);
      formData.append("Annualfinancial_document", values.Documentation[0].Annualfinancial_document);
      formData.append("Auditedfinancial_document", values.Documentation[0].Auditedfinancial_document);
      formData.append("absenceof_document", values.Documentation[0].absenceof_document);
      formData.append("IdentityCardorPassport_document", values.Documentation[0].IdentityCardorPassport_document);
      formData.append("permanentaddress_document", values.Documentation[0].permanentaddress_document);
      formData.append("Accountopening_document", values.Documentation[0].Accountopening_document);
      formData.append("AML_CTF_document", values.Documentation[0].AML_CTF_document);
      formData.append("signature", values.signature);

      try {
        setIsLoading(true);
        const params = {
          url: "user-businessrForm",
          method: "POST",
          data: formData,
          header: "image"
        };
        // console.log(formData, "formData")
        const BussinessResponse = await makeApiRequest(params);
        // console.log("BussinessResponse----", BussinessResponse);
        if (BussinessResponse.status == true) {
          toast.success(BussinessResponse.message)
          setIsLoading(false);
          setTimeout(() => {
            Navigate("/")
          }, 2000);


        }
        else {
          toast.warn(BussinessResponse.message)
          setIsLoading(false);
        }
      } catch (error) {
        // console.log('error---', error);
        toast.error("Internal Server Error");
        setIsLoading(false);
      }
    }
  });
  // console.log('formik', formik.errors)

  const imagehandleChange = (event) => {
    const { name } = event.currentTarget;
    const file = event.currentTarget.files[0];

    if (file) {
      formik.setFieldValue(`Documentation[0].${name}`, file);
      const fileURL = URL.createObjectURL(file);
      setFilePreviews((prevPreviews) => ({
        ...prevPreviews,
        [name]: fileURL,
      }));
    }
  };

  const handleRemoveFile = (fieldName) => {
    formik.setFieldValue(`Documentation[0].${fieldName}`, null);
    setFilePreviews((prevPreviews) => ({
      ...prevPreviews, [fieldName]: null,
    }));
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Reset the input value
    }
  };

  return (
    <div className='business'>
      <div className={localStorage.getItem("userCredentials") ? "custom-one d-flex justify-content-center align-items-center pb-5" : "custom d-flex justify-content-center align-items-center pb-5"}>
        <div className='container py-4 whl-business-form-box'>
          <ToastContainer />
          <div className='row business-form-00'>
            <div className='col-lg-10 mx-auto mt-lg-4'>
              <h4 className='mb-auto business-title text-uppercase py-4'>Onboarding form</h4>

              <div className='whole-business-form'>
                <form id="businessform" onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="col-lg-12">
                      {/* <h6 className="mb-4 business-input-headings">Name of the Company</h6> */}
                      <div>
                        <div className="mb-4 pt-2">
                          <label htmlFor="firstName" className="form-label  business-input-headings">Full name of the Company:</label>
                          <input
                            type="text"
                            className="form-control business-input-group-box"
                            name="firstname"
                            id="firstName"
                            value={formik.values.firstname}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="First name"

                          />
                          {formik.touched.firstname && formik.errors.firstname && <div className="text-danger rq-msg">{formik.errors.firstname}</div>}
                        </div>
                        <div className="mb-4 pt-2">
                          <label htmlFor="abbreviateName" className="form-label  business-input-headings">Abbreviated name of the Company: <span className='option-css'>(if applicable)</span></label>
                          <input
                            type="text"
                            className="form-control business-input-group-box"
                            name="abbreviatename"
                            id="abbreviateName"
                            value={formik.values.abbreviatename}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Abbreviate name"
                          />

                        </div>
                        <div className="mb-4 pt-2">
                          <label htmlFor="foreignLanguageName" className="form-label business-input-headings">Foreign languages name of the Company :<span className='option-css'>(if applicable)</span> </label>
                          <input
                            type="text"
                            className="form-control business-input-group-box"
                            name="foreignlanguagesname"
                            id="foreignLanguageName"
                            value={formik.values.foreignlanguagesname}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Languages name"
                          />

                        </div>

                      </div>
                    </div>
                  </div>


                  {/* <==============> */}

                  <div className="row">
                    <div className="col-lg-12">
                      <h6 className="business-input-headings-one">State registration information</h6>
                      <div>
                        <div className="mb-4 pt-2">
                          <label htmlFor="date" className="form-label  business-input-headings">Date :</label>
                          <input
                            type="date"
                            className="form-control business-input-group-box"
                            name="Date"
                            id="date"
                            value={formik.values.Date}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Date"
                          />
                          {formik.touched.Date && formik.errors.Date && <div className="text-danger rq-msg">{formik.errors.Date}</div>}
                        </div>
                        <div className="mb-4 pt-2">
                          <label htmlFor="registrationNumber" className="form-label  business-input-headings">Registration number :</label>
                          <input
                            type="text"
                            className="form-control business-input-group-box"
                            name="Registration_number"
                            id="registrationNumber"
                            value={formik.values.Registration_number}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Registration number"
                          />
                          {formik.touched.Registration_number && formik.errors.Registration_number && <div className="text-danger rq-msg">{formik.errors.Registration_number}</div>}
                        </div>
                        <div className="mb-4 pt-2">
                          <label htmlFor="registrationAuthority" className="form-label  business-input-headings">Name of the registration authority :</label>
                          <input
                            type="text"
                            className="form-control business-input-group-box"
                            name="Registration_authority"
                            id="registrationAuthority"
                            value={formik.values.Registration_authority}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Registration authority"
                          />
                          {formik.touched.Registration_authority && formik.errors.Registration_authority && <div className="text-danger rq-msg">{formik.errors.Registration_authority}</div>}
                        </div>
                        <div className="mb-4 pt-2">
                          <label htmlFor="placeOfRegistration" className="form-label  business-input-headings">Place of registration :</label>
                          <input
                            type="text"
                            className="form-control business-input-group-box"
                            name="placeofregistration"
                            id="placeOfRegistration"
                            value={formik.values.placeofregistration}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Place of registration"
                          />
                          {formik.touched.placeofregistration && formik.errors.placeofregistration && <div className="text-danger rq-msg">{formik.errors.placeofregistration}</div>}
                        </div>
                        <div className="mb-4 pt-2">
                          <label htmlFor="legalAddress" className="form-label  business-input-headings">Legal address :</label>
                          <textarea
                            type="text"
                            className="form-control business-input-group-box"
                            name="legal_address"
                            id="legalAddress"
                            value={formik.values.legal_address}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Legal address"
                          />
                          {formik.touched.legal_address && formik.errors.legal_address && <div className="text-danger rq-msg">{formik.errors.legal_address}</div>}
                        </div>
                        <div className="mb-4 pt-2">
                          <label htmlFor="postalAddress" className="form-label  business-input-headings">Postal address :</label>
                          <textarea
                            type="text"
                            className="form-control business-input-group-box"
                            name="postal_address"
                            id="postalAddress"
                            value={formik.values.postal_address}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Postal address"
                          />
                          {formik.touched.postal_address && formik.errors.postal_address && <div className="text-danger rq-msg">{formik.errors.postal_address}</div>}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <===================> */}

                  <div className="row mb-4">
                    <div className="col-lg-12">
                      <div className="">
                        {/* <div className="col-lg-4 col-12 mb-3"> */}
                        <label className="mb-2  business-input-headings">E-mail, URL :</label>
                        <input
                          type="email"
                          className="form-control business-input-group-box"
                          name="emailURL"
                          value={formik.values.emailURL}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="Email address"
                          aria-label="Email address"
                        />
                        {formik.touched.emailURL && formik.errors.emailURL && <div className="text-danger rq-msg">{formik.errors.emailURL}</div>}
                      </div>

                      {/* </div> */}
                    </div>
                  </div>

                  {/* <======================> */}

                  <div className="row mb-4">
                    <h6 className="mb-3  business-input-headings-one">License information : <span className='option-css'>(if applicable)</span></h6>
                    <div className="col-lg-12">
                      <div className="">
                        <label htmlFor="activity" className="form-label  business-input-headings">Type of activity :</label>
                        <input
                          type="text"
                          className="form-control business-input-group-box"
                          name="licensein_formation.typeof_Activity"
                          id="activity"
                          value={formik.values.licensein_formation.typeof_Activity}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder=""
                        />
                      </div>
                      <div className="mt-4">
                        <label htmlFor="LicenseNo" className="form-label  business-input-headings">License No :</label>
                        <input
                          type="text"
                          className="form-control business-input-group-box"
                          name="licensein_formation.licenseNo"
                          id="LicenseNo"
                          value={formik.values.licensein_formation.licenseNo}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder=""
                        />
                      </div>
                      <div className="mt-4">
                        <label htmlFor="Dateofissue" className="form-label  business-input-headings">Date of issue  :</label>
                        <input
                          type="date"
                          className="form-control business-input-group-box"
                          name="licensein_formation.dateofIssue"
                          id="Dateofissue"
                          value={formik.values.licensein_formation.dateofIssue}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder=""
                        />
                      </div>
                      <div className="mt-4">
                        <label htmlFor="Issuingauthority" className="form-label  business-input-headings">Issuing authority  :</label>
                        <input
                          type="text"
                          className="form-control business-input-group-box"
                          name="licensein_formation.issuing_Authority"
                          id="Issuingauthority"
                          value={formik.values.licensein_formation.issuing_Authority}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder=""
                        />
                      </div>
                      <div className="mt-4">
                        <label htmlFor="expiration" className="form-label  business-input-headings">Validity Date (date of expiration)  :</label>
                        <input
                          type="date"
                          className="form-control business-input-group-box"
                          name="licensein_formation.validity"
                          id="expiration"
                          value={formik.values.licensein_formation.validity}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>

                  {/* <===================> */}

                  <div className="row mb-4">
                    <div className="row">
                      <div className="col-lg-6 col-12 mb-3">
                        <label className="mb-2  business-input-headings">Country of Tax Residency :</label>
                        <input
                          type="text"
                          className="form-control business-input-group-box"
                          name="licensein_formation.country_taxresidency"
                          value={formik.values.licensein_formation.country_taxresidency}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="Tax Residency"
                        />
                      </div>
                      <div className="col-lg-6 col-12 mb-3">
                        <label className="mb-2  business-input-headings">TAX number :</label>
                        <input
                          type="text"
                          className="form-control business-input-group-box"
                          name="licensein_formation.taxnumber"
                          value={formik.values.licensein_formation.taxnumber}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="TAX number"
                        />
                      </div>
                    </div>
                  </div>

                  {/* <=========================> */}

                  <div className="row mb-4">
                    <h6 className="mb-3 business-input-headings-one">Share capital:</h6>
                    <div className="">
                      <label htmlFor="registeredshare" className="form-label  business-input-headings">Amount of the registered share capital  :</label>
                      <input
                        type="text"
                        className="form-control business-input-group-box"
                        name="sharecaptial.registered_sharecapital"
                        id="registeredshare"
                        value={formik.values.sharecaptial.registered_sharecapital}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Enter a Captial Amount"
                      />
                      {formik.touched.sharecaptial?.registered_sharecapital && formik.errors.sharecaptial?.registered_sharecapital && <div className="text-danger rq-msg">{formik.errors.sharecaptial?.registered_sharecapital}</div>}
                    </div>
                    <div className="mt-4">
                      <label htmlFor="paidshare" className="form-label  business-input-headings">Amount of the paid share capital :</label>
                      <input
                        type="text"
                        className="form-control business-input-group-box"
                        name="sharecaptial.paid_sharecaptial"
                        id="paidshare"
                        value={formik.values.sharecaptial.paid_sharecaptial}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Enter the Actual Amount Paid"
                      />
                      {formik.touched.sharecaptial?.paid_sharecaptial && formik.errors.sharecaptial?.paid_sharecaptial && <div className="text-danger rq-msg">{formik.errors.sharecaptial?.paid_sharecaptial}</div>}
                    </div>
                    <div className="mt-4">
                      <label htmlFor="personnelNumber" className="form-label  business-input-headings">Actual number of personnel :</label>
                      <input
                        type="text"
                        className="form-control business-input-group-box"
                        name="sharecaptial.numberofpersonnel"
                        id="personnelNumber"
                        value={formik.values.sharecaptial.numberofpersonnel}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Number of personnel"
                      />
                      {formik.touched.sharecaptial?.numberofpersonnel && formik.errors.sharecaptial?.numberofpersonnel && <div className="text-danger rq-msg">{formik.errors.sharecaptial?.numberofpersonnel}</div>}
                    </div>
                  </div>

                  {/* <========================> */}

                  <div className="row mt-4">
                    <div className="col-lg-12">
                      <div>
                        <h6 className="business-input-headings">
                          Are there any insolvency (bankruptcy) legal proceedings against the Company? Are there any legally effective court decisions on insolvency (bankruptcy) in respect of the Company?
                        </h6>
                      </div>
                      <div className="d-flex mt-3">
                        <div className="form-check me-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="sharecaptial.legalproceedingsCompany.bankruptcy"
                            id="insolvencyYes"
                            value={true}
                            checked={formik.values.sharecaptial.legalproceedingsCompany.bankruptcy == true}
                            onChange={() => formik.setFieldValue("sharecaptial.legalproceedingsCompany.bankruptcy", true)}
                          />
                          <label className="form-check-label" htmlFor="insolvencyYes">
                            Yes
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="sharecaptial.legalproceedingsCompany.bankruptcy"
                            id="insolvencyNo"
                            value={false}
                            checked={formik.values.sharecaptial.legalproceedingsCompany.bankruptcy == false}
                            onChange={() => formik.setFieldValue("sharecaptial.legalproceedingsCompany.bankruptcy", false)}
                          />
                          <label className="form-check-label" htmlFor="insolvencyNo">
                            No
                          </label>
                        </div>
                      </div>

                      <div className="mt-4">
                        <label htmlFor="specifyDetails" className="form-label  business-input-headings">If Yes, please specify :</label>
                        <input
                          type="text"
                          className="form-control business-input-group-box mb-4"
                          name='sharecaptial.legalproceedingsCompany.bankruptcyspecify'
                          id="specifyDetails"
                          value={formik.values.sharecaptial.legalproceedingsCompany.bankruptcyspecify}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled={!formik.values.sharecaptial.legalproceedingsCompany.bankruptcy}
                          placeholder=""
                        />
                        {/* {formik.touched.sharecaptial?.legalproceedingsCompany?.bankruptcyspecify && formik.errors.sharecaptial?.legalproceedingsCompany?.bankruptcyspecify && <div className="text-danger rq-msg">{formik.errors.sharecaptial?.legalproceedingsCompany?.bankruptcyspecify}</div>} */}
                      </div>
                    </div>
                  </div>

                  {/* <==============================> */}

                  <div className="row my-4">
                    <div className="col-lg-12">
                      <div>
                        <h6 className="business-input-headings">
                          Are there any facts of your financial obligations default due to lack of funds in bank accounts?
                        </h6>
                      </div>
                      <div className="d-flex my-3">
                        <div className="form-check me-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="sharecaptial.financialobligations.lackoffunds_bankaccounts"
                            id="financialObligationsYes"
                            value={true}
                            checked={formik.values.sharecaptial.financialobligations.lackoffunds_bankaccounts == true}
                            onChange={() => formik.setFieldValue("sharecaptial.financialobligations.lackoffunds_bankaccounts", true)}
                          />
                          <label className="form-check-label" htmlFor="financialObligationsYes">
                            Yes
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="sharecaptial.financialobligations.lackoffunds_bankaccounts"
                            id="financialObligationsNo"
                            value={false}
                            checked={formik.values.sharecaptial.financialobligations.lackoffunds_bankaccounts == false}
                            onChange={() => formik.setFieldValue("sharecaptial.financialobligations.lackoffunds_bankaccounts", false)}
                          />
                          <label className="form-check-label" htmlFor="financialObligationsNo">
                            No
                          </label>
                        </div>
                      </div>

                      <div className="mt-4">
                        <label htmlFor="IfYes" className="form-label  business-input-headings">If Yes, please specify :</label>
                        <input
                          type="text"
                          className="form-control business-input-group-box mb-4"
                          name='sharecaptial.financialobligations.financialspecify'
                          id="IfYes"
                          value={formik.values.sharecaptial.financialobligations.financialspecify}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled={!formik.values.sharecaptial.financialobligations.lackoffunds_bankaccounts}
                          placeholder=""
                        />
                        {/* {formik.touched.sharecaptial?.financialobligations?.financialspecify && formik.errors.sharecaptial?.financialobligations?.financialspecify && <div className="text-danger rq-msg">{formik.errors.sharecaptial?.financialobligations?.financialspecify}</div>} */}
                      </div>
                    </div>
                  </div>

                  {/* <=====================> */}

                  <div className='admin'>
                    <div className="row mt-4 mb-4">
                      <div className="col-lg-12">
                        <h6 className="my-3 business-input-headings-one">
                          Administrative bodies of the Company (list of the Board of Directors <span className='option-css'>(if applicable)</span>, CEO or representative)
                        </h6>

                        <div className='d-flex align-items-start gap-2'>
                          <div className='mt-1'>1. </div>
                          <div>
                            <input
                              type="text"
                              className="form-control business-input-group-box mb-1"
                              name='listoftheBoardofDirectors.DirectorFirstnominee'
                              id='DirectorFirstnominee'
                              value={formik.values.listoftheBoardofDirectors.DirectorFirstnominee}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              placeholder="Enter First Nominee"
                            />
                            <div className='business-input-headings'>Is this Director acting as a nominee?</div>
                            <div className="d-flex mt-2 mb-4">
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="listoftheBoardofDirectors.Directoractingnominee1"
                                  id="directorNomineeYes1"
                                  value={true}
                                  checked={formik.values.listoftheBoardofDirectors.Directoractingnominee1 == true}
                                  onChange={() => formik.setFieldValue('listoftheBoardofDirectors.Directoractingnominee1', true)}
                                />
                                <label className="form-check-label" htmlFor="directorNomineeYes1">
                                  Yes
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="listoftheBoardofDirectors.Directoractingnominee1"
                                  id="directorNomineeNo1"
                                  value={false}
                                  checked={formik.values.listoftheBoardofDirectors.Directoractingnominee1 == false}
                                  onChange={() => formik.setFieldValue('listoftheBoardofDirectors.Directoractingnominee1', false)}
                                />
                                <label className="form-check-label" htmlFor="directorNomineeNo1">
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='d-flex align-items-start gap-2'>
                          <div className='mt-1'>2. </div>
                          <div>
                            <input
                              type="text"
                              className="form-control business-input-group-box mb-1"
                              name='listoftheBoardofDirectors.DirectorSecondnominee'
                              id='DirectorSecondnominee'
                              value={formik.values.listoftheBoardofDirectors.DirectorSecondnominee}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              placeholder="Enter Second Nominee"
                            />

                            <div className='business-input-headings'>Is this Director acting as a nominee?</div>
                            <div className="d-flex mt-2 mb-4">
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="listoftheBoardofDirectors.Directoractingnominee2"
                                  id="directorNomineeYes2"
                                  value={true}
                                  checked={formik.values.listoftheBoardofDirectors.Directoractingnominee2 == true}
                                  onChange={() => formik.setFieldValue('listoftheBoardofDirectors.Directoractingnominee2', true)}
                                />
                                <label className="form-check-label" htmlFor="directorNomineeYes2">
                                  Yes
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="listoftheBoardofDirectors.Directoractingnominee2"
                                  id="directorNomineeNo2"
                                  value={false}
                                  checked={formik.values.listoftheBoardofDirectors.Directoractingnominee2 == false}
                                  onChange={() => formik.setFieldValue('listoftheBoardofDirectors.Directoractingnominee2', false)}
                                />
                                <label className="form-check-label" htmlFor="directorNomineeNo2">
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='d-flex align-items-start gap-2'>
                          <div className='mt-1'>3. </div>
                          <div>
                            <input
                              type="text"
                              className="form-control business-input-group-box mb-1"
                              name='listoftheBoardofDirectors.DirectorThirdnominee'
                              id='DirectorThirdnominee'
                              value={formik.values.listoftheBoardofDirectors.DirectorThirdnominee}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              placeholder="Enter Third Nominee"
                            />
                            <div className='business-input-headings'>Is this Director acting as a nominee?</div>
                            <div className="d-flex mt-2 mb-4">
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="listoftheBoardofDirectors.Directoractingnominee3"
                                  id="directorNomineeYes3"
                                  value={true}
                                  checked={formik.values.listoftheBoardofDirectors.Directoractingnominee3 == true}
                                  onChange={() => formik.setFieldValue('listoftheBoardofDirectors.Directoractingnominee3', true)}
                                />
                                <label className="form-check-label" htmlFor="directorNomineeYes3">
                                  Yes
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="listoftheBoardofDirectors.Directoractingnominee3"
                                  id="directorNomineeNo3"
                                  value={false}
                                  checked={formik.values.listoftheBoardofDirectors.Directoractingnominee3 == false}
                                  onChange={() => formik.setFieldValue('listoftheBoardofDirectors.Directoractingnominee3', false)}
                                />
                                <label className="form-check-label" htmlFor="directorNomineeNo3">
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='d-flex align-items-start gap-2'>
                          <div className='mt-1'>4. </div>
                          <div>
                            <input
                              type="text"
                              className="form-control business-input-group-box mb-1"
                              name='listoftheBoardofDirectors.DirectorFourthnominee'
                              id='DirectorFourthnominee'
                              value={formik.values.listoftheBoardofDirectors.DirectorFourthnominee}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              placeholder="Enter Fourth Nominee"
                            />
                            <div className='business-input-headings'>Is this Director acting as a nominee?</div>
                            <div className="d-flex mt-2 mb-4">
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="listoftheBoardofDirectors.Directoractingnominee4"
                                  id="directorNomineeYes4"
                                  value={true}
                                  checked={formik.values.listoftheBoardofDirectors.Directoractingnominee4 == true}
                                  onChange={() => formik.setFieldValue('listoftheBoardofDirectors.Directoractingnominee4', true)}
                                />
                                <label className="form-check-label" htmlFor="directorNomineeYes4">
                                  Yes
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="listoftheBoardofDirectors.Directoractingnominee4"
                                  id="directorNomineeNo4"
                                  value={false}
                                  checked={formik.values.listoftheBoardofDirectors.Directoractingnominee4 == false}
                                  onChange={() => formik.setFieldValue('listoftheBoardofDirectors.Directoractingnominee4', false)}
                                />
                                <label className="form-check-label" htmlFor="directorNomineeNo4">
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='d-flex align-items-start gap-2'>
                          <div className='mt-1'>5. </div>
                          <div>
                            <input
                              type="text"
                              className="form-control business-input-group-box mb-1"
                              name='listoftheBoardofDirectors.DirectorFifthnominee'
                              id='DirectorFifthnominee'
                              value={formik.values.listoftheBoardofDirectors.DirectorFifthnominee}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              placeholder="Enter Nominee"
                            />
                            <div className='business-input-headings'>Is this Director acting as a nominee?</div>
                            <div className="d-flex mt-2 mb-4">
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="listoftheBoardofDirectors.Directoractingnominee5"
                                  id="directorNomineeYes5"
                                  value={true}
                                  checked={formik.values.listoftheBoardofDirectors.Directoractingnominee5 == true}
                                  onChange={() => formik.setFieldValue('listoftheBoardofDirectors.Directoractingnominee5', true)}
                                />
                                <label className="form-check-label" htmlFor="directorNomineeYes5">
                                  Yes
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="listoftheBoardofDirectors.Directoractingnominee5"
                                  id="directorNomineeNo5"
                                  value={false}
                                  checked={formik.values.listoftheBoardofDirectors.Directoractingnominee5 == false}
                                  onChange={() => formik.setFieldValue('listoftheBoardofDirectors.Directoractingnominee5', false)}
                                />
                                <label className="form-check-label" htmlFor="directorNomineeNo5">
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <label htmlFor="CFO" className="my-3 business-input-headings">Name of the CFO (or equivalent)</label>
                        <input
                          type="text"
                          className="form-control business-input-group-box"
                          name='NameoftheCFO'
                          id='CFO'
                          value={formik.values.NameoftheCFO}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="Name of the CFO"
                        />
                        {formik.touched.NameoftheCFO && formik.errors.NameoftheCFO && <div className="text-danger rq-msg">{formik.errors.NameoftheCFO}</div>}
                        <h6 className=" my-3 business-input-headings">
                          Information about the person(s) who is/are the Company’s ultimate beneficial owner(s), with an indication of the share of equity
                          (full details of the beneficial owner specified in the application in the form prescribed in Appendix 3)
                        </h6>
                        <textarea
                          className="form-control business-input-group-box"
                          rows="4"
                          name='details_Beneficialowner'
                          value={formik.values.details_Beneficialowner}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="Details of the beneficial owner(s)"></textarea>
                        {formik.touched.details_Beneficialowner && formik.errors.details_Beneficialowner && <div className="text-danger rq-msg">{formik.errors.details_Beneficialowner}</div>}
                      </div>
                    </div>

                  </div>

                  {/* <======================> */}

                  <div className="row mt-4 mb-2">
                    <div className="col-lg-12">
                      <h6 className="business-input-headings-one">
                        Details of Shareholders (participants) holding 25% or more of the authorized capital with an indication of percentage of shares held
                      </h6>

                      <p className="mb-3">*In case where the shareholder is a nominee/nominee company please provide all corporate documents up to the physical persons (including trust deeds/trust declarations)</p>

                      <div className="mb-4 d-flex align-items-start gap-2">
                        <div className="mt-1">1. </div>
                        <div>
                          <input
                            type='text'
                            name='detailsofShareholders.firstshareholder'
                            className='form-control business-input-group-box mb-1'
                            value={formik.values.detailsofShareholders.firstshareholder}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder='Enter First Shareholder'>
                          </input>
                          <p className="mb-2 business-input-headings">Percentage of shares held</p>
                          <input
                            type="number"
                            name='detailsofShareholders.percentageofsharesheld1'
                            min="0"
                            max="100"
                            className='form-control business-input-group-box mb-1'
                            value={formik.values.detailsofShareholders.percentageofsharesheld1}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder='Enter Percentage'
                          />
                          <p className="mb-2 business-input-headings">Is this Shareholder acting as a nominee?</p>
                          <div className="d-flex mt-2 mb-3">
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="detailsofShareholders.Shareholdernomuinee1"
                                id="shareholderNomineeYes1"
                                value={true}
                                checked={formik.values.detailsofShareholders.Shareholdernomuinee1 == true}
                                onChange={() => formik.setFieldValue('detailsofShareholders.Shareholdernomuinee1', true)}
                              />
                              <label className="form-check-label" htmlFor="shareholderNomineeYes1">
                                Yes
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="detailsofShareholders.Shareholdernomuinee1"
                                id="shareholderNomineeNo1"
                                value={false}
                                checked={formik.values.detailsofShareholders.Shareholdernomuinee1 == false}
                                onChange={() => formik.setFieldValue('detailsofShareholders.Shareholdernomuinee1', false)}
                              />
                              <label className="form-check-label" htmlFor="shareholderNomineeNo1">
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mb-4 d-flex align-items-start gap-2">
                        <div className="mt-1">2. </div>
                        <div>
                          <input
                            type='text'
                            name='detailsofShareholders.secondshareholder'
                            className='form-control business-input-group-box mb-1'
                            value={formik.values.detailsofShareholders.secondshareholder}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder='Enter Second Shareholder'>
                          </input>
                          <p className="mb-2 business-input-headings">Percentage of shares held</p>
                          <input
                            type="number"
                            name='detailsofShareholders.percentageofsharesheld2'
                            min="0"
                            max="100"
                            className='form-control business-input-group-box mb-1'
                            value={formik.values.detailsofShareholders.percentageofsharesheld2}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder='Enter Percentage'
                          />
                          <p className="mb-2 business-input-headings">Is this Shareholder acting as a nominee?</p>
                          <div className="d-flex mt-2 mb-3">
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="detailsofShareholders.Shareholdernomuinee2"
                                id="shareholderNomineeYes2"
                                value={true}
                                checked={formik.values.detailsofShareholders.Shareholdernomuinee2 == true}
                                onChange={() => formik.setFieldValue('detailsofShareholders.Shareholdernomuinee2', true)}
                              />
                              <label className="form-check-label" htmlFor="shareholderNomineeYes2">
                                Yes
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="detailsofShareholders.Shareholdernomuinee2"
                                id="shareholderNomineeNo2"
                                value={false}
                                checked={formik.values.detailsofShareholders.Shareholdernomuinee2 == false}
                                onChange={() => formik.setFieldValue('detailsofShareholders.Shareholdernomuinee2', false)}
                              />
                              <label className="form-check-label" htmlFor="shareholderNomineeNo2">
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mb-4 d-flex align-items-start gap-2">
                        <div className="mt-1">3. </div>
                        <div>
                          <input
                            type='text'
                            name='detailsofShareholders.thirdshareholder'
                            className='form-control business-input-group-box mb-1'
                            value={formik.values.detailsofShareholders.thirdshareholder}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder='Enter Third Shareholder'>
                          </input>
                          <p className="mb-2 business-input-headings">Percentage of shares held</p>
                          <input
                            type="number"
                            name='detailsofShareholders.percentageofsharesheld3'
                            min="0"
                            max="100"
                            className='form-control business-input-group-box mb-1'
                            value={formik.values.detailsofShareholders.percentageofsharesheld3}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder='Enter Percentage'
                          />
                          <p className="mb-2 business-input-headings">Is this Shareholder acting as a nominee?</p>
                          <div className="d-flex mt-2 mb-3">
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="detailsofShareholders.Shareholdernomuinee3"
                                id="shareholderNomineeYes3"
                                value={true}
                                checked={formik.values.detailsofShareholders.Shareholdernomuinee3 == true}
                                onChange={() => formik.setFieldValue('detailsofShareholders.Shareholdernomuinee3', true)}
                              />
                              <label className="form-check-label" htmlFor="shareholderNomineeYes3">
                                Yes
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="detailsofShareholders.Shareholdernomuinee3"
                                id="shareholderNomineeNo3"
                                value={false}
                                checked={formik.values.detailsofShareholders.Shareholdernomuinee3 == false}
                                onChange={() => formik.setFieldValue('detailsofShareholders.Shareholdernomuinee3', false)}
                              />
                              <label className="form-check-label" htmlFor="shareholderNomineeNo3">
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mb-4 d-flex align-items-start gap-2">
                        <div className="mt-1">4. </div>
                        <div>
                          <input
                            type='text'
                            name='detailsofShareholders.fourthshareholder'
                            className='form-control business-input-group-box mb-1'
                            value={formik.values.detailsofShareholders.fourthshareholder}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder='Enter Fourth Shareholder'>
                          </input>
                          <p className="mb-2 business-input-headings">Percentage of shares held</p>
                          <input
                            type="number"
                            name='detailsofShareholders.percentageofsharesheld4'
                            min="0"
                            max="100"
                            className='form-control business-input-group-box mb-1'
                            value={formik.values.detailsofShareholders.percentageofsharesheld4}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder='Enter Percentage'
                          />
                          <p className="mb-2 business-input-headings">Is this Shareholder acting as a nominee?</p>
                          <div className="d-flex mt-2 mb-3">
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="detailsofShareholders.Shareholdernomuinee4"
                                id="shareholderNomineeYes4"
                                value={true}
                                checked={formik.values.detailsofShareholders.Shareholdernomuinee4 == true}
                                onChange={() => formik.setFieldValue('detailsofShareholders.Shareholdernomuinee4', true)}
                              />
                              <label className="form-check-label" htmlFor="shareholderNomineeYes4">
                                Yes
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="detailsofShareholders.Shareholdernomuinee4"
                                id="shareholderNomineeNo4"
                                value={false}
                                checked={formik.values.detailsofShareholders.Shareholdernomuinee4 == false}
                                onChange={() => formik.setFieldValue('detailsofShareholders.Shareholdernomuinee4', false)}
                              />
                              <label className="form-check-label" htmlFor="shareholderNomineeNo4">
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <========================> */}

                  <div className='row'>
                    <div className='col-lg-12'>
                    <h6 className="mb-3 business-input-headings-one">General Information:</h6>
                      <div className='mb-3'>
                        <label htmlFor="goods" className="form-label business-input-headings">Types of activity (goods, services)</label>
                        <input
                          type="text"
                          className="form-control"
                          name='Types_activity'
                          id="goods"
                          value={formik.values.Types_activity}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="In Few Words Mention About Your Business"
                        />
                        {formik.touched.Types_activity && formik.errors.Types_activity && <div className="text-danger rq-msg">{formik.errors.Types_activity}</div>}
                      </div>
                      <div className='mb-3'>
                        <label htmlFor="Region" className="form-label business-input-headings">Region (countries) of the business activity?</label>
                        <input
                          type="text"
                          className="form-control"
                          name='Business_activity'
                          id="Region"
                          value={formik.values.Business_activity}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="The Countries You Operate"
                        />
                        {formik.touched.Business_activity && formik.errors.Business_activity && <div className="text-danger rq-msg">{formik.errors.Business_activity}</div>}
                      </div>


                      <label htmlFor="reasonForNo" className="form-label business-input-headings">Does your Company have an obligation for the preparation of financial statements to comply with applicable legislation framework?</label>
                      <div className='d-flex mb-4'>
                        <div className="form-check me-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="financial_legislation.legislation_framework"
                            id="financialStatementYes"
                            value={true}
                            checked={formik.values.financial_legislation.legislation_framework == true}
                            onChange={() => formik.setFieldValue("financial_legislation.legislation_framework", true)}
                          />
                          <label className="form-check-label" htmlFor="financialStatementYes">
                            Yes
                          </label>
                        </div>
                        <div className="form-check me-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="financial_legislation.legislation_framework"
                            id="financialStatementNo"
                            value={false}
                            checked={formik.values.financial_legislation.legislation_framework == false}
                            onChange={() => formik.setFieldValue("financial_legislation.legislation_framework", false)}
                          />
                          <label className="form-check-label" htmlFor="financialStatementNo">
                            No
                          </label>
                        </div>
                      </div>


                      <div className="my-4">
                        <label htmlFor="reasonForNo" className="form-label  business-input-headings">If No, please specify the reason:</label>
                        <input
                          type="text"
                          className="form-control"
                          name='financial_legislation.legislationspecify'
                          id="reasonForNo"
                          value={formik.values.financial_legislation.legislationspecify}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled={formik.values.financial_legislation.legislation_framework}
                          placeholder=""
                        />
                      </div>
                      <div className="my-4">
                        <label htmlFor="businessDuration" className="form-label  business-input-headings">How long has the Company been carrying on business activity in this area?</label>
                        <input
                          type="text"
                          className="form-control"
                          name='business_activityarea'
                          id="businessDuration"
                          value={formik.values.business_activityarea}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="Mention the Years"
                        />
                        {formik.touched.business_activityarea && formik.errors.business_activityarea && <div className="text-danger rq-msg">{formik.errors.business_activityarea}</div>}
                      </div>

                      <div className="my-4">
                        <label htmlFor="planningTurnover" className="form-label  business-input-headings">Please specify the planning turnover of the Company (monthly/yearly), average amount of transactions:</label>
                        <input type="text"
                          className="form-control"
                          name='averageamount_Transactions'
                          id="planningTurnover"
                          value={formik.values.averageamount_Transactions}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="Enter the Amount"
                        />
                        {formik.touched.averageamount_Transactions && formik.errors.averageamount_Transactions && <div className="text-danger rq-msg">{formik.errors.averageamount_Transactions}</div>}
                      </div>

                      <div className="my-4">
                        <label htmlFor="companyWebsite" className="form-label  business-input-headings">Please specify the website of the Company or other sources of information about the Company:</label>
                        <input
                          type="text"
                          className="form-control"
                          name='website_Company'
                          id="companyWebsite"
                          value={formik.values.website_Company}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="Mention the Name"
                        />
                        {formik.touched.website_Company && formik.errors.website_Company && <div className="text-danger rq-msg">{formik.errors.website_Company}</div>}
                      </div>

                      <div className="my-4">
                        <label htmlFor="companyHistory" className="form-label  business-input-headings">History, reputation, segment of the market, and competitors of the Company:</label>
                        <input
                          type="text"
                          className="form-control"
                          name='competitors_Company'
                          id="companyHistory"
                          value={formik.values.competitors_Company}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="In brief.."
                        />
                        {formik.touched.competitors_Company && formik.errors.competitors_Company && <div className="text-danger rq-msg">{formik.errors.competitors_Company}</div>}
                      </div>

                    </div>
                  </div>

                  {/* <=======================> */}

                  <div className='row'>
                    <div className='col-lg-12'>
                      <div>
                        <h6 className='text-center my-4 business-input-headings-one'>General AML Policies, Practices and Procedures</h6>
                      </div>
                      <div>
                        <div className="d-flex my-2">
                          <span style={{ minWidth: '50px' }}>1.(a)</span>
                          <span class="">
                            <label for="" class="form-label business-input-headings">Is your institution subject to the supervision of any regulatory authority?</label>
                            <div className='d-flex mb-4'>
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies1.regulatory_authority"
                                  id="1Yes"
                                  value="Yes"
                                  checked={formik.values.generalAMLpolicies.Amlpolicies1.regulatory_authority == "Yes"}
                                  onChange={formik.handleChange}
                                // onChange={() => formik.setFieldValue("generalAMLpolicies.Amlpolicies1.regulatory_authority", "Yes")}


                                />
                                <label className="form-check-label" htmlFor="1Yes">
                                  Yes
                                </label>
                              </div>
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies1.regulatory_authority"
                                  id="1No"
                                  value="No"
                                  onChange={formik.handleChange}
                                  checked={formik.values.generalAMLpolicies.Amlpolicies1.regulatory_authority == "No"}
                                />
                                <label className="form-check-label" htmlFor="1No">
                                  No
                                </label>
                              </div>
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies1.regulatory_authority"
                                  id="n/a"
                                  value="N/A"
                                  onChange={formik.handleChange}
                                  checked={formik.values.generalAMLpolicies.Amlpolicies1.regulatory_authority == "N/A"}
                                />
                                <label className="form-check-label" htmlFor="n/a">
                                  N/A
                                </label>
                              </div>
                            </div>
                          </span>

                        </div>

                        <div className="d-flex my-2">
                          <span style={{ minWidth: '50px' }}>1.(b)</span>
                          <span class="">
                            <label for="formGroupExampleInput" class="form-label business-input-headings">If yes, please provide the name of the supervisory/regulatory authority, including the one responsible for supervising and controlling money laundering prevention:</label>
                            <input
                              type="text"
                              class="form-control"
                              name='generalAMLpolicies.Amlpolicies1.moneylaundering_prevention'
                              id="formGroupExampleInput"
                              value={formik.values.generalAMLpolicies.Amlpolicies1.moneylaundering_prevention}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              disabled={formik.values.generalAMLpolicies.Amlpolicies1.regulatory_authority !== "Yes"}
                              placeholder=""
                            />
                          </span>
                          {/* <span></span> */}
                        </div>

                        <div className="d-flex my-2">
                          <span style={{ minWidth: '50px' }}>1.(c)</span>
                          <span class="">
                            <label for="licensenumber" class="form-label business-input-headings">Please provide your registration / operating license number</label>
                            <input
                              type="text"
                              class="form-control"
                              name='generalAMLpolicies.Amlpolicies1.operating_licensenumber'
                              id="licensenumber"
                              value={formik.values.generalAMLpolicies.Amlpolicies1.operating_licensenumber}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              disabled={formik.values.generalAMLpolicies.Amlpolicies1.regulatory_authority !== "Yes"}
                              placeholder=""
                            />
                          </span>
                          <span></span>
                        </div>

                        <div className="d-flex my-2">
                          <span style={{ minWidth: '50px' }}>2.</span>
                          <span class="">
                            <label for="" class="form-label business-input-headings">Does your country adhere to the 40+9 anti-money laundering and counter-terrorism financing recommendations developed by the Financial Action Task Force (FATF)?</label>
                            <div className='d-flex mb-4'>
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies2"
                                  id="2Yes"
                                  value="Yes"
                                  checked={formik.values.generalAMLpolicies.Amlpolicies2 == "Yes"}
                                  onChange={formik.handleChange}
                                />
                                <label className="form-check-label" htmlFor="2Yes">
                                  Yes
                                </label>
                              </div>
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies2"
                                  id="2No"
                                  value="No"
                                  checked={formik.values.generalAMLpolicies.Amlpolicies2 == "No"}
                                  onChange={formik.handleChange}
                                />
                                <label className="form-check-label" htmlFor="2No">
                                  No
                                </label>
                              </div>
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies2"
                                  id="2n/a"
                                  value="N/A"
                                  checked={formik.values.generalAMLpolicies.Amlpolicies2 == "N/A"}
                                  onChange={formik.handleChange}
                                />
                                <label className="form-check-label" htmlFor="2n/a">
                                  N/A
                                </label>
                              </div>
                            </div>
                          </span>

                        </div>

                        <div className="d-flex my-2">
                          <span style={{ minWidth: '50px' }}>3.(a)</span>
                          <span class="">
                            <label for="" class="form-label business-input-headings">Does your institution have a designated Compliance Officer responsible for the overall AML/CTF program? Please provide AML/CTF program.</label>
                            <div className='d-flex mb-4'>
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies3.AML_CTFprogram1"
                                  id="3Yes"
                                  value="Yes"
                                  checked={formik.values.generalAMLpolicies.Amlpolicies3.AML_CTFprogram1 == "Yes"}
                                  onChange={() => formik.setFieldValue("generalAMLpolicies.Amlpolicies3.AML_CTFprogram1", "Yes")}
                                />
                                <label className="form-check-label" htmlFor="3Yes">
                                  Yes
                                </label>
                              </div>
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies3.AML_CTFprogram1"
                                  id="3No"
                                  value="No"
                                  checked={formik.values.generalAMLpolicies.Amlpolicies3.AML_CTFprogram1 == "No"}
                                  onChange={() => formik.setFieldValue("generalAMLpolicies.Amlpolicies3.AML_CTFprogram1", "No")}
                                />
                                <label className="form-check-label" htmlFor="3No">
                                  No
                                </label>
                              </div>
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies3.AML_CTFprogram1"
                                  id="3n/a"
                                  value="N/A"
                                  checked={formik.values.generalAMLpolicies.Amlpolicies3.AML_CTFprogram1 == "N/A"}
                                  onChange={() => formik.setFieldValue("generalAMLpolicies.Amlpolicies3.AML_CTFprogram1", "N/A")}
                                />
                                <label className="form-check-label" htmlFor="3n/a">
                                  N/A
                                </label>
                              </div>
                            </div>
                          </span>

                        </div>
                        <div className="d-flex my-2">
                          <span style={{ minWidth: '50px' }}>3.(b)</span>
                          <div>
                            <div class="row mb-3">
                              <label for="Yes11" class="col-sm-6 col-form-label business-input-headings">If “Yes,” please provide:</label>
                            </div>
                            <div class="row mb-3">
                              <label for="CompleteName" class="col-sm-6 col-form-label business-input-headings">Complete Name:</label>
                              <div class="col-sm-6">
                                <input
                                  type="text"
                                  class="form-control"
                                  name='generalAMLpolicies.Amlpolicies3.Aml_CTFprogram2.CompleteName1'
                                  id="CompleteName"
                                  value={formik.values.generalAMLpolicies.Amlpolicies3.Aml_CTFprogram2.CompleteName1}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  disabled={formik.values.generalAMLpolicies.Amlpolicies3.AML_CTFprogram1 !== "Yes"}
                                />
                              </div>
                            </div>
                            <div class="row mb-3">
                              <label for="Title" class="col-sm-6 col-form-label business-input-headings">Position Title:</label>
                              <div class="col-sm-6">
                                <input
                                  type="text"
                                  class="form-control"
                                  name='generalAMLpolicies.Amlpolicies3.Aml_CTFprogram2.Position_Title1'
                                  id="Title"
                                  value={formik.values.generalAMLpolicies.Amlpolicies3.Aml_CTFprogram2.Position_Title1}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  disabled={formik.values.generalAMLpolicies.Amlpolicies3.AML_CTFprogram1 !== "Yes"}
                                />
                              </div>
                            </div>
                            <div class="row mb-3">
                              <label for="Mailing" class="col-sm-6 col-form-label business-input-headings">Mailing Address:</label>
                              <div class="col-sm-6">
                                <input
                                  type="text"
                                  class="form-control"
                                  name='generalAMLpolicies.Amlpolicies3.Aml_CTFprogram2.Mailing_Address1'
                                  id="Mailing"
                                  value={formik.values.generalAMLpolicies.Amlpolicies3.Aml_CTFprogram2.Mailing_Address1}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  disabled={formik.values.generalAMLpolicies.Amlpolicies3.AML_CTFprogram1 !== "Yes"}
                                />
                              </div>
                            </div>
                            <div class="row mb-3">
                              <label for="TelephoneNumber" class="col-sm-6 col-form-label business-input-headings">Telephone Number:</label>
                              <div class="col-sm-6">
                                <input
                                  type="text"
                                  class="form-control"
                                  name='generalAMLpolicies.Amlpolicies3.Aml_CTFprogram2.Telephone_Number1'
                                  id="TelephoneNumber"
                                  value={formik.values.generalAMLpolicies.Amlpolicies3.Aml_CTFprogram2.Telephone_Number1}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  disabled={formik.values.generalAMLpolicies.Amlpolicies3.AML_CTFprogram1 !== "Yes"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex my-2">
                          <span style={{ minWidth: '50px' }}>3.(c)</span>
                          <div>
                            <span class="my-4"><label for="" class="form-label  business-input-headings">Does your institution have a designated employee responsible for disputes( chargebacks) and penalties?</label>
                              <div className='d-flex mb-4'>
                                <div className="form-check me-3">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="generalAMLpolicies.Amlpolicies3.Aml_CTFprogram3"
                                    id="4Yes"
                                    value="Yes"
                                    checked={formik.values.generalAMLpolicies.Amlpolicies3.Aml_CTFprogram3 == "Yes"}
                                    onChange={() => formik.setFieldValue("generalAMLpolicies.Amlpolicies3.Aml_CTFprogram3", "Yes")}
                                  />
                                  <label className="form-check-label" htmlFor="4Yes">
                                    Yes
                                  </label>
                                </div>
                                <div className="form-check me-3">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="generalAMLpolicies.Amlpolicies3.Aml_CTFprogram3"
                                    id="4No"
                                    value="No"
                                    checked={formik.values.generalAMLpolicies.Amlpolicies3.Aml_CTFprogram3 == "No"}
                                    onChange={() => formik.setFieldValue("generalAMLpolicies.Amlpolicies3.Aml_CTFprogram3", "No")}
                                  />
                                  <label className="form-check-label" htmlFor="4No">
                                    No
                                  </label>
                                </div>
                                <div className="form-check me-3">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="generalAMLpolicies.Amlpolicies3.Aml_CTFprogram3"
                                    id="4n/a"
                                    value="N/A"
                                    checked={formik.values.generalAMLpolicies.Amlpolicies3.Aml_CTFprogram3 == "N/A"}
                                    onChange={() => formik.setFieldValue("generalAMLpolicies.Amlpolicies3.Aml_CTFprogram3", "N/A")}
                                  />
                                  <label className="form-check-label" htmlFor="4n/a">
                                    N/A
                                  </label>
                                </div>
                              </div>
                            </span>

                          </div>
                        </div>
                        <div className="d-flex my-2">
                          <span style={{ minWidth: '50px' }}>3.(d)</span>
                          <div>
                            <div class="row mb-3">
                              <label for="Yes22" class="col-sm-6 col-form-label business-input-headings">If “Yes,” please provide:</label>
                            </div>
                            <div class="row mb-3">
                              <label for="Complete1" class="col-sm-6 col-form-label business-input-headings">Complete Name:</label>
                              <div class="col-sm-6">
                                <input
                                  type="text"
                                  class="form-control"
                                  name='generalAMLpolicies.Amlpolicies3.Aml_CTFprogram4.CompleteName2'
                                  id="Complete1"
                                  value={formik.values.generalAMLpolicies.Amlpolicies3.Aml_CTFprogram4.CompleteName2}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  disabled={formik.values.generalAMLpolicies.Amlpolicies3.Aml_CTFprogram3 !== "Yes"}
                                />
                              </div>
                            </div>
                            <div class="row mb-3">
                              <label for="Title4" class="col-sm-6 col-form-label business-input-headings">Position Title:</label>
                              <div class="col-sm-6">
                                <input
                                  type="text"
                                  class="form-control"
                                  name='generalAMLpolicies.Amlpolicies3.Aml_CTFprogram4.Position_Title2'
                                  id="Title4"
                                  value={formik.values.generalAMLpolicies.Amlpolicies3.Aml_CTFprogram4.Position_Title2}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  disabled={formik.values.generalAMLpolicies.Amlpolicies3.Aml_CTFprogram3 !== "Yes"}
                                />
                              </div>
                            </div>
                            <div class="row mb-3">
                              <label for="inputEmail3" class="col-sm-6 col-form-label business-input-headings">Mailing Address:</label>
                              <div class="col-sm-6">
                                <input
                                  type="email"
                                  class="form-control"
                                  name='generalAMLpolicies.Amlpolicies3.Aml_CTFprogram4.Mailing_Address2'
                                  id="inputEmail3"
                                  value={formik.values.generalAMLpolicies.Amlpolicies3.Aml_CTFprogram4.Mailing_Address2}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  disabled={formik.values.generalAMLpolicies.Amlpolicies3.Aml_CTFprogram3 !== "Yes"}
                                />
                              </div>
                            </div>
                            <div class="row mb-3">
                              <label for="inputEmail34" class="col-sm-6 col-form-label business-input-headings">Telephone Number:</label>
                              <div class="col-sm-6">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="inputEmail34"
                                  name='generalAMLpolicies.Amlpolicies3.Aml_CTFprogram4.Telephone_Number2'
                                  value={formik.values.generalAMLpolicies.Amlpolicies3.Aml_CTFprogram4.Telephone_Number2}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  disabled={formik.values.generalAMLpolicies.Amlpolicies3.Aml_CTFprogram3 !== "Yes"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex my-2">
                          <span style={{ minWidth: '50px' }}>4 .</span>
                          <div>
                            <label for="" class="form-label business-input-headings">Does your institution have a written legal and regulatory compliance program that includes a designated Compliance Officer that is responsible for coordinating and overseeing the AML program on a day to day basis?</label>
                            <div className='d-flex mb-4'>
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies4"
                                  id="5Yes"
                                  value="Yes"
                                  checked={formik.values.generalAMLpolicies.Amlpolicies4 == "Yes"}
                                  onChange={formik.handleChange}
                                />
                                <label className="form-check-label" htmlFor="5Yes">
                                  Yes
                                </label>
                              </div>
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies4"
                                  id="5No"
                                  value="No"
                                  checked={formik.values.generalAMLpolicies.Amlpolicies4 == "No"}
                                  onChange={formik.handleChange}
                                />
                                <label className="form-check-label" htmlFor="5No">
                                  No
                                </label>
                              </div>
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies4"
                                  id="5N/a"
                                  value="N/A"
                                  checked={formik.values.generalAMLpolicies.Amlpolicies4 == "N/A"}
                                  onChange={formik.handleChange}
                                />
                                <label className="form-check-label" htmlFor="5N/a">
                                  N/A
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex my-2">
                          <span style={{ minWidth: '50px' }}>5.(a)</span>
                          <div>
                            <label for="" class="form-label business-input-headings">Does your institution’s AML program require approval of the Board of Directors?</label>
                            <div className='d-flex mb-4'>
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies5.AMLprogram_Directors"
                                  id="6Yes"
                                  value="Yes"
                                  checked={formik.values.generalAMLpolicies.Amlpolicies5.AMLprogram_Directors == "Yes"}
                                  onChange={() => formik.setFieldValue("generalAMLpolicies.Amlpolicies5.AMLprogram_Directors", "Yes")}
                                />
                                <label className="form-check-label" htmlFor="6Yes">
                                  Yes
                                </label>
                              </div>
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies5.AMLprogram_Directors"
                                  id="6No"
                                  value="No"
                                  checked={formik.values.generalAMLpolicies.Amlpolicies5.AMLprogram_Directors == "No"}
                                  onChange={() => formik.setFieldValue("generalAMLpolicies.Amlpolicies5.AMLprogram_Directors", "No")}
                                />
                                <label className="form-check-label" htmlFor="6No">
                                  No
                                </label>
                              </div>
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies5.AMLprogram_Directors"
                                  id="6na"
                                  value="N/A"
                                  checked={formik.values.generalAMLpolicies.Amlpolicies5.AMLprogram_Directors == "N/A"}
                                  onChange={() => formik.setFieldValue("generalAMLpolicies.Amlpolicies5.AMLprogram_Directors", "N/A")}
                                />
                                <label className="form-check-label" htmlFor="6na">
                                  N/A
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex my-2">
                          <span style={{ minWidth: '50px' }}>5.(b)</span>
                          <div>
                            <label for="recentdate" class="form-label business-input-headings">Please specify the most recent date the AML program was last updated:</label>
                            <input
                              type="date"
                              class="form-control"
                              name='generalAMLpolicies.Amlpolicies5.AMLprogram_DirectorsSpecify'
                              id="recentdate"
                              value={formik.values.generalAMLpolicies.Amlpolicies5.AMLprogram_DirectorsSpecify}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              disabled={formik.values.generalAMLpolicies.Amlpolicies5.AMLprogram_Directors !== "Yes"}
                              placeholder=""
                            />
                          </div>
                        </div>

                        <div className="d-flex my-2">
                          <span style={{ minWidth: '50px' }}>6.</span>
                          <div>
                            <label for="" class="form-label business-input-headings">Are your institution’s AML policies and procedures being applied to all your branches/subsidiaries both in the home country and in locations outside that jurisdiction?</label>
                            <div className='d-flex mb-4'>
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies6"
                                  id="7Yes"
                                  value="Yes"
                                  checked={formik.values.generalAMLpolicies.Amlpolicies6 == "Yes"}
                                  onChange={formik.handleChange}
                                />
                                <label className="form-check-label" htmlFor="7Yes">
                                  Yes
                                </label>
                              </div>
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies6"
                                  id="7No"
                                  value="No"
                                  checked={formik.values.generalAMLpolicies.Amlpolicies6 == "No"}
                                  onChange={formik.handleChange}
                                />
                                <label className="form-check-label" htmlFor="7No">
                                  No
                                </label>
                              </div>
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies6"
                                  id="7na"
                                  value="N/A"
                                  checked={formik.values.generalAMLpolicies.Amlpolicies6 == "N/A"}
                                  onChange={formik.handleChange}
                                />
                                <label className="form-check-label" htmlFor="7na">
                                  N/A
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex my-2">
                          <span style={{ minWidth: '50px' }}>7.</span>
                          <div>
                            <label for="" class="form-label business-input-headings">Does your institution have written policies documenting the processes that you have in place to prevent and detect any suspicious transactions/terrorist financing activities?</label>
                            <div className='d-flex mb-4'>
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies7"
                                  id="8Yes"
                                  value="Yes"
                                  checked={formik.values.generalAMLpolicies.Amlpolicies7 == "Yes"}
                                  onChange={formik.handleChange}
                                />
                                <label className="form-check-label" htmlFor="8Yes">
                                  Yes
                                </label>
                              </div>
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies7"
                                  id="8No"
                                  value="No"
                                  checked={formik.values.generalAMLpolicies.Amlpolicies7 == "No"}
                                  onChange={formik.handleChange}
                                />
                                <label className="form-check-label" htmlFor="8No">
                                  No
                                </label>
                              </div>
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies7"
                                  id="8n/a"
                                  value="N/A"
                                  checked={formik.values.generalAMLpolicies.Amlpolicies7 == "N/A"}
                                  onChange={formik.handleChange}
                                />
                                <label className="form-check-label" htmlFor="8n/a">
                                  N/A
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex my-2">
                          <span style={{ minWidth: '50px' }}>8.</span>
                          <div>
                            <label for="" class="form-label business-input-headings">Does your institution have policies and procedures to prohibit any accounts/relationships with shell banks/organizations?</label>
                            <div className='d-flex mb-4'>
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies8"
                                  id="9Yes"
                                  value="Yes"
                                  checked={formik.values.generalAMLpolicies.Amlpolicies8 == "Yes"}
                                  onChange={formik.handleChange}
                                />
                                <label className="form-check-label" htmlFor="9Yes">
                                  Yes
                                </label>
                              </div>
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies8"
                                  id="9No"
                                  value="No"
                                  checked={formik.values.generalAMLpolicies.Amlpolicies8 == "No"}
                                  onChange={formik.handleChange}
                                />
                                <label className="form-check-label" htmlFor="9No">
                                  No
                                </label>
                              </div>
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies8"
                                  id="9Na"
                                  value="N/A"
                                  checked={formik.values.generalAMLpolicies.Amlpolicies8 == "N/A"}
                                  onChange={formik.handleChange}
                                />
                                <label className="form-check-label" htmlFor="9Na">
                                  N/A
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex my-2">
                          <span style={{ minWidth: '50px' }}>9.(a)</span>
                          <div>
                            <label for="" class="form-label business-input-headings">Was your institution subject to any investigation related to ML/CTF?.</label>
                            <div className='d-flex mb-4'>
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies9.investigation_MLandCTF"
                                  id="10Yes"
                                  value="Yes"
                                  checked={formik.values.generalAMLpolicies.Amlpolicies9.investigation_MLandCTF == "Yes"}
                                  onChange={formik.handleChange}
                                />
                                <label className="form-check-label" htmlFor="10Yes">
                                  Yes
                                </label>
                              </div>
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies9.investigation_MLandCTF"
                                  id="10No"
                                  value="No"
                                  checked={formik.values.generalAMLpolicies.Amlpolicies9.investigation_MLandCTF == "No"}
                                  onChange={formik.handleChange}
                                />
                                <label className="form-check-label" htmlFor="10No">
                                  No
                                </label>
                              </div>
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies9.investigation_MLandCTF"
                                  id="10Na"
                                  value="N/A"
                                  checked={formik.values.generalAMLpolicies.Amlpolicies9.investigation_MLandCTF == "N/A"}
                                  onChange={formik.handleChange}
                                />
                                <label className="form-check-label" htmlFor="10Na">
                                  N/A
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex my-2">
                          <span style={{ minWidth: '50px' }}>9.(b)</span>
                          <div>
                            <label for="pleasespecify1" class="form-label business-input-headings">If ‘Yes’ please specify when:</label>
                            <input
                              type="date"
                              class="form-control"
                              name='generalAMLpolicies.Amlpolicies9.investigation_MLandCTFSpecify'
                              id="pleasespecify1"
                              value={formik.values.generalAMLpolicies.Amlpolicies9.investigation_MLandCTFSpecify}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              disabled={formik.values.generalAMLpolicies.Amlpolicies9.investigation_MLandCTF !== "Yes"}
                              placeholder=""

                            />
                          </div>
                        </div>
                        <div className="d-flex my-2">
                          <span style={{ minWidth: '50px' }}>10.(a)</span>
                          <div>
                            <label htmlFor="" className="form-label business-input-headings">
                              Has your institution received any regulatory enforcement action currently or within the past three years?
                            </label>
                            <div className="d-flex mb-4">
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies10.enforcement_action"
                                  id="11Yes"
                                  value="Yes"
                                  checked={formik.values.generalAMLpolicies.Amlpolicies10.enforcement_action == "Yes"}
                                  onChange={formik.handleChange}
                                />
                                <label className="form-check-label" htmlFor="11Yes">
                                  Yes
                                </label>
                              </div>
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies10.enforcement_action"
                                  id="11No"
                                  value="No"
                                  checked={formik.values.generalAMLpolicies.Amlpolicies10.enforcement_action == "No"}
                                  onChange={formik.handleChange}
                                />
                                <label className="form-check-label" htmlFor="11No">
                                  No
                                </label>
                              </div>
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies10.enforcement_action"
                                  id="11Na"
                                  value="N/A"
                                  checked={formik.values.generalAMLpolicies.Amlpolicies10.enforcement_action == "N/A"}
                                  onChange={formik.handleChange}
                                />
                                <label className="form-check-label" htmlFor="11Na">
                                  N/A
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex my-2">
                          <span style={{ minWidth: '50px' }}>10.(b)</span>
                          <div>
                            <label htmlFor="" className="form-label business-input-headings">
                              If ‘Yes’, has the regulatory enforcement action resulted in fines or penalties being assessed?
                            </label>
                            <div className="d-flex mb-4">
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies10.enforcement_actionAssessed"
                                  id="12Yes"
                                  value="Yes"
                                  checked={formik.values.generalAMLpolicies.Amlpolicies10.enforcement_actionAssessed == "Yes"}
                                  onChange={formik.handleChange}
                                />
                                <label className="form-check-label" htmlFor="12Yes">
                                  Yes
                                </label>
                              </div>
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies10.enforcement_actionAssessed"
                                  id="12No"
                                  value="No"
                                  checked={formik.values.generalAMLpolicies.Amlpolicies10.enforcement_actionAssessed == "No"}
                                  onChange={formik.handleChange}
                                />
                                <label className="form-check-label" htmlFor="12No">
                                  No
                                </label>
                              </div>
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies10.enforcement_actionAssessed"
                                  id="12Na"
                                  value="N/A"
                                  checked={formik.values.generalAMLpolicies.Amlpolicies10.enforcement_actionAssessed == "N/A"}
                                  onChange={formik.handleChange}
                                />
                                <label className="form-check-label" htmlFor="12Na">
                                  N/A
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex my-2">
                          <span style={{ minWidth: '50px' }}>11.(a)</span>
                          <div>
                            <label for="" class="form-label business-input-headings">Was your institution ever got fined for ML/CTF crimes?</label>
                            <div className='d-flex mb-4'>
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies11.MLandCTF_crimes"
                                  id="13Yes"
                                  value="Yes"
                                  checked={formik.values.generalAMLpolicies.Amlpolicies11.MLandCTF_crimes == "Yes"}
                                  onChange={formik.handleChange}
                                />
                                <label className="form-check-label" htmlFor="13Yes">
                                  Yes
                                </label>
                              </div>
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies11.MLandCTF_crimes"
                                  id="13No"
                                  value="No"
                                  checked={formik.values.generalAMLpolicies.Amlpolicies11.MLandCTF_crimes == "No"}
                                  onChange={formik.handleChange}
                                />
                                <label className="form-check-label" htmlFor="13No">
                                  No
                                </label>
                              </div>
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies11.MLandCTF_crimes"
                                  id="13Na"
                                  value="N/A"
                                  checked={formik.values.generalAMLpolicies.Amlpolicies11.MLandCTF_crimes == "N/A"}
                                  onChange={formik.handleChange}
                                />
                                <label className="form-check-label" htmlFor="13Na">
                                  N/A
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex my-2">
                          <span style={{ minWidth: '50px' }}>11.(b)</span>
                          <div>
                            <label for="fine" class="form-label business-input-headings">If ‘Yes’ please specify when and provide additional details regarding the fine:</label>
                            <input
                              type="text"
                              class="form-control"
                              name='generalAMLpolicies.Amlpolicies11.MLandCTF_crimesSpecify'
                              id="fine"
                              placeholder=""
                              value={formik.values.generalAMLpolicies.Amlpolicies11.MLandCTF_crimesSpecify}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              disabled={formik.values.generalAMLpolicies.Amlpolicies11.MLandCTF_crimes !== "Yes"}
                            />
                          </div>
                        </div>
                        <div className="d-flex my-2">
                          <span style={{ minWidth: '50px' }}>12.</span>
                          <div>
                            <label for="" class="form-label business-input-headings">Does your institution have procedures in place to monitor accounts and transactions in order to prevent and detect suspicious activity?</label>
                            <div className='d-flex mb-4'>
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies12"
                                  id="14Yes"
                                  value="Yes"
                                  checked={formik.values.generalAMLpolicies.Amlpolicies12 == "Yes"}
                                  onChange={formik.handleChange}
                                />
                                <label className="form-check-label" htmlFor="14Yes">
                                  Yes
                                </label>
                              </div>
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies12"
                                  id="14No"
                                  value="No"
                                  checked={formik.values.generalAMLpolicies.Amlpolicies12 == "No"}
                                  onChange={formik.handleChange}
                                />
                                <label className="form-check-label" htmlFor="14No">
                                  No
                                </label>
                              </div>
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies12"
                                  id="14Na"
                                  value="N/A"
                                  checked={formik.values.generalAMLpolicies.Amlpolicies12 == "N/A"}
                                  onChange={formik.handleChange}
                                />
                                <label className="form-check-label" htmlFor="14Na">
                                  N/A
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex my-2">
                          <span style={{ minWidth: '50px' }}>13.</span>
                          <div>
                            <label for="industries" class="form-label business-input-headings">Which categories of customers or industries according to AML/CTF program/corporate policies are restricted or prohibited by your institution? Please, specify the industries.</label>
                            <input
                              type="text"
                              name='generalAMLpolicies.Amlpolicies13'
                              class="form-control"
                              id="industries"
                              value={formik.values.generalAMLpolicies.Amlpolicies13}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              placeholder=""
                            />
                            {formik.touched.generalAMLpolicies?.Amlpolicies13 && formik.errors.generalAMLpolicies?.Amlpolicies13 && <div className="text-danger rq-msg">{formik.errors.generalAMLpolicies.Amlpolicies13}</div>}
                          </div>
                        </div>
                        <div className="d-flex my-2">
                          <span style={{ minWidth: '50px' }}>14.(a)</span>
                          <div>
                            <label for="" class="form-label business-input-headings">Does your institution restrict or prohibit opening/maintaining accounts from any jurisdiction?</label>
                            <div className='d-flex mb-4'>
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies14.prohibit_opening"
                                  id="15Yes"
                                  value="Yes"
                                  checked={formik.values.generalAMLpolicies.Amlpolicies14.prohibit_opening == "Yes"}
                                  onChange={formik.handleChange}
                                />
                                <label className="form-check-label" htmlFor="15Yes">
                                  Yes
                                </label>
                              </div>
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies14.prohibit_opening"
                                  id="15No"
                                  value="No"
                                  checked={formik.values.generalAMLpolicies.Amlpolicies14.prohibit_opening == "No"}
                                  onChange={formik.handleChange}
                                />
                                <label className="form-check-label" htmlFor="15No">
                                  No
                                </label>
                              </div>
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="generalAMLpolicies.Amlpolicies14.prohibit_opening"
                                  id="15Na"
                                  value="N/A"
                                  checked={formik.values.generalAMLpolicies.Amlpolicies14.prohibit_opening == "N/A"}
                                  onChange={formik.handleChange}
                                />
                                <label className="form-check-label" htmlFor="15Na">
                                  N/A
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex my-2">
                          <span style={{ minWidth: '50px' }}>14.(b)</span>
                          <div>
                            <label for="countries" class="form-label business-input-headings">If ‘Yes’ please specify the restricted/prohibited countries.</label>
                            <input
                              type="text"
                              class="form-control"
                              name='generalAMLpolicies.Amlpolicies14.prohibit_openingSpecify'
                              id="countries"
                              value={formik.values.generalAMLpolicies.Amlpolicies14.prohibit_openingSpecify}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              disabled={formik.values.generalAMLpolicies.Amlpolicies14.prohibit_opening !== "Yes"}
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div>
                          <h6 class="text-center my-4 business-input-headings-one">PROTECTION OF PERSONAL DATA</h6>
                          <p>The personal data requested above may be recorded in one or several databases in accordance with applicable legislation. REM INTERNATIONAL PAYMENTS INC. may outsource the processing and storage of this data. You expressly authorize REM INTERNATIONAL PAYMENTS INC. to collect and process  this personal data. You may make a written request to consult the data concerning yourself and to rectify any inaccuracies in this data. REM INTERNATIONAL PAYMENTS INC. may record or process your personal data for the purpose of managing its contractual relationships with you, including transfer of personal data to our partners in order to provide services to you.</p>
                        </div>
                        <div>
                          <h6 class="text-center my-4 business-input-headings-one">FOREIGN ACCOUNT TAX COMPLIANCE ACT (FATCA)</h6>
                          <p>We would like to draw your attention to the USA's Foreign Account Tax Compliance Act (a.k.a FATCA) and its possible implications*. Please indicate if you or any of your beneficial owners are a U.S citizen or resident or are otherwise to be considered as a 'US Person' as per FATCA:</p>
                          <p><span className='fw-bold'>N0  </span> I/We hereby declare that we are familiar with the FATCA guidelines and possible implications on the parties and that I am/we and any of our beneficial owners are NOT a U.S citizen or resident or are otherwise to be considered as a 'US Person' as per FATCA</p>
                          <p><span className='fw-bold'>YES  </span> I/We hereby declare that we are familiar with the FATCA guidelines and possible implications on the parties and that I am/we or any of our beneficial owners ARE a U.S citizen or resident or are otherwise to be considered as a 'US Person' as per FATCA and will provide us as soon as possible with the relevant documents, information, W-9 forms and other materials as requested by us.</p>
                          <p>I/We agree to indemnify REM INTERNATIONAL PAYMENTS INC. in respect of any false or misleading information regarding my/our “U.S. person” status for U.S. federal income tax purposes. I/We agree to notify REM INTERNATIONAL PAYMENTS INC. 30 days of any change in the aforementioned statement.</p>
                          <p className='link-size'>For more information see also: http://www.irs.gov/Businesses/Corporations/Foreign-Account-Tax-Compliance-Act-FATCA.</p>
                        </div>
                        <div className='req-doc'>
                          <h6 class="text-center my-4 business-input-headings-one">REQUESTED DOCUMENTATION</h6>
                          <p>The following documents (certified copies) shall be attached to the Questionnaire:</p>

                          <div className="d-flex my-2">
                            <span style={{ minWidth: '50px' }} className=''>1.</span>
                            <div>
                              <label for="Memorandum_document" class="form-label business-input-headings">Memorandum and Articles of Association</label>
                              <input
                                type="file"
                                className="form-control business-input-group-box"
                                name='Memorandum_document'
                                id="Memorandum_document"
                                accept="image/*,application/pdf"
                                onChange={imagehandleChange}
                                onBlur={formik.handleBlur}
                                ref={fileInputRef}
                              />
                              {formik.touched.Documentation?.[0]?.Memorandum_document && formik.errors.Documentation?.[0]?.Memorandum_document && (
                                <div className="text-danger rq-msg">{formik.errors.Documentation[0].Memorandum_document}</div>
                              )}

                              {filePreviews?.Memorandum_document && (
                                <div className="my-2">
                                  <div>Uploaded: {formik.values.Documentation[0]?.Memorandum_document.name}</div>
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => handleRemoveFile('Memorandum_document')}
                                  >
                                    Remove File
                                  </button>
                                </div>
                              )}

                            </div>
                          </div>
                          <div className="d-flex my-2">
                            <span style={{ minWidth: '50px' }} className=''>2.</span>
                            <div>
                              <label for="Incorporation" class="form-label business-input-headings">Certificate of Incorporation / Registration</label>
                              <input
                                type="file"
                                name='Incorporation_document'
                                className="form-control business-input-group-box"
                                id="Incorporation"
                                accept="image/*,application/pdf"
                                onChange={imagehandleChange}
                                onBlur={formik.handleBlur}
                                ref={fileInputRef}
                              />
                              {formik.touched.Documentation?.[0]?.Incorporation_document && formik.errors.Documentation?.[0]?.Incorporation_document && (
                                <div className="text-danger rq-msg">{formik.errors.Documentation[0].Incorporation_document}</div>
                              )}
                              {filePreviews?.Incorporation_document && (
                                <div className="my-2">
                                  <div>Uploaded: {formik.values.Documentation[0]?.Incorporation_document.name}</div>
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => handleRemoveFile('Incorporation_document')}
                                  >
                                    Remove File
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="d-flex my-2">
                            <span style={{ minWidth: '50px' }} className=''>3.</span>
                            <div>
                              <label for="Directors" class="form-label business-input-headings">Certificate of Directors</label>
                              <input
                                type="file"
                                name='Directors_document'
                                className="form-control business-input-group-box"
                                id="Directors"
                                accept="image/*,application/pdf"
                                onChange={imagehandleChange}
                                onBlur={formik.handleBlur}
                                ref={fileInputRef}
                              />
                              {formik.touched.Documentation?.[0]?.Directors_document && formik.errors.Documentation?.[0]?.Directors_document && (
                                <div className="text-danger rq-msg">{formik.errors.Documentation[0].Directors_document}</div>
                              )}
                              {filePreviews?.Directors_document && (
                                <div className="my-2">
                                  <div>Uploaded: {formik.values.Documentation[0]?.Directors_document.name}</div>
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => handleRemoveFile('Directors_document')}
                                  >
                                    Remove File
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="d-flex my-2">
                            <span style={{ minWidth: '50px' }} className=''>4.</span>
                            <div>
                              <label for="good" class="form-label business-input-headings">Certificate of good standing (in the event that the company is older than one year old)</label>
                              <input
                                type="file"
                                name='goodstanding_document'
                                className="form-control business-input-group-box"
                                id="good"
                                accept="image/*,application/pdf"
                                onChange={imagehandleChange}
                                onBlur={formik.handleBlur}
                                ref={fileInputRef}
                              />
                              {formik.touched.Documentation?.[0]?.goodstanding_document && formik.errors.Documentation?.[0]?.goodstanding_document && (
                                <div className="text-danger rq-msg">{formik.errors.Documentation[0].goodstanding_document}</div>
                              )}
                              {filePreviews?.goodstanding_document && (
                                <div className="my-2">
                                  <div>Uploaded: {formik.values.Documentation[0]?.goodstanding_document.name}</div>
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => handleRemoveFile('goodstanding_document')}
                                  >
                                    Remove File
                                  </button>
                                </div>
                              )}

                            </div>
                          </div>
                          <div className="d-flex my-2">
                            <span style={{ minWidth: '50px' }} className=''>5.</span>
                            <div>
                              <label for="Incumbency" class="form-label business-input-headings">Certificate of Incumbency</label>
                              <input
                                type="file"
                                name='Incumbency_document'
                                className="form-control business-input-group-box"
                                id="Incumbency"
                                accept="image/*,application/pdf"
                                onChange={imagehandleChange}
                                onBlur={formik.handleBlur}
                                ref={fileInputRef}
                              />
                              {formik.touched.Documentation?.[0]?.Incumbency_document && formik.errors.Documentation?.[0]?.Incumbency_document && (
                                <div className="text-danger rq-msg">{formik.errors.Documentation[0].Incumbency_document}</div>
                              )}
                              {filePreviews?.Incumbency_document && (
                                <div className="my-2">
                                  <div>Uploaded: {formik.values.Documentation[0]?.Incumbency_document.name}</div>
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => handleRemoveFile('Incumbency_document')}
                                  >
                                    Remove File
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="d-flex my-2">
                            <span style={{ minWidth: '50px' }} className=''>6.</span>
                            <div>
                              <label for="Shareholders" class="form-label business-input-headings">Certificate of Shareholders, Register of shareholders or other equivalent document</label>
                              <input
                                type="file"
                                name='Shareholders_document'
                                className="form-control business-input-group-box"
                                id="Shareholders"
                                accept="image/*,application/pdf"
                                onChange={imagehandleChange}
                                onBlur={formik.handleBlur}
                                ref={fileInputRef}
                              />
                              {formik.touched.Documentation?.[0]?.Shareholders_document && formik.errors.Documentation?.[0]?.Shareholders_document && (
                                <div className="text-danger rq-msg">{formik.errors.Documentation[0].Shareholders_document}</div>
                              )}
                              {filePreviews?.Shareholders_document && (
                                <div className="my-2">
                                  <div>Uploaded: {formik.values.Documentation[0]?.Shareholders_document.name}</div>
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => handleRemoveFile('Shareholders_document')}
                                  >
                                    Remove File
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="d-flex my-2">
                            <span style={{ minWidth: '50px' }} className=''>7.</span>
                            <div>
                              <label for="Legal" class="form-label business-input-headings">Legal ownership structure certified by the UBO or the person who effectively controls the company</label>
                              <input
                                type="file"
                                name='Legalownership_document'
                                className="form-control business-input-group-box"
                                id="Legal"
                                accept="image/*,application/pdf"
                                onChange={imagehandleChange}
                                onBlur={formik.handleBlur}
                                ref={fileInputRef}
                              />
                              {formik.touched.Documentation?.[0]?.Legalownership_document && formik.errors.Documentation?.[0]?.Legalownership_document && (
                                <div className="text-danger rq-msg">{formik.errors.Documentation[0].Legalownership_document}</div>
                              )}
                              {filePreviews?.Legalownership_document && (
                                <div className="my-2">
                                  <div>Uploaded: {formik.values.Documentation[0]?.Legalownership_document.name}</div>
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => handleRemoveFile('Legalownership_document')}
                                  >
                                    Remove File
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="d-flex my-2">
                            <span style={{ minWidth: '50px' }} className=''>8.</span>
                            <div>
                              <label for="authorization" class="form-label business-input-headings">Operating license and\or authorization</label>
                              <input
                                type="file"
                                name='Operatinglicense_document'
                                className="form-control business-input-group-box"
                                id="authorization"
                                accept="image/*,application/pdf"
                                onChange={imagehandleChange}
                                onBlur={formik.handleBlur}
                                ref={fileInputRef}
                              />
                              {formik.touched.Documentation?.[0]?.Operatinglicense_document && formik.errors.Documentation?.[0]?.Operatinglicense_document && (
                                <div className="text-danger rq-msg">{formik.errors.Documentation[0].Operatinglicense_document}</div>
                              )}
                              {filePreviews?.Operatinglicense_document && (
                                <div className="my-2">
                                  <div>Uploaded: {formik.values.Documentation[0]?.Operatinglicense_document.name}</div>
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => handleRemoveFile('Operatinglicense_document')}
                                  >
                                    Remove File
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="d-flex my-2">
                            <span style={{ minWidth: '50px' }} className=''>9.</span>
                            <div>
                              <label for="Trust" class="form-label business-input-headings">Declaration of Trust <span className='option-css'>(if applicable)</span></label>
                              <input
                                type="file"
                                name='DeclarationofTrust_document'
                                className="form-control business-input-group-box"
                                id="Trust"
                                accept="image/*,application/pdf"
                                onChange={imagehandleChange}
                                onBlur={formik.handleBlur}
                                ref={fileInputRef}
                              />

                              {filePreviews?.DeclarationofTrust_document && (
                                <div className="my-2">
                                  <div>Uploaded: {formik.values.Documentation[0]?.DeclarationofTrust_document.name}</div>
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => handleRemoveFile('DeclarationofTrust_document')}
                                  >
                                    Remove File
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="d-flex my-2">
                            <span style={{ minWidth: '50px' }} className=''>10.</span>
                            <div>
                              <label for="Agreement" class="form-label business-input-headings">Trust Deeds/ Trust Settlement Agreement <span className='option-css'>(if applicable)</span> </label>
                              <input
                                type="file"
                                name='TrustSettlement_document'
                                className="form-control business-input-group-box"
                                id="Agreement"
                                accept="image/*,application/pdf"
                                onChange={imagehandleChange}
                                onBlur={formik.handleBlur}
                                ref={fileInputRef}
                              />
                              {filePreviews?.TrustSettlement_document && (
                                <div className="my-2">
                                  <div>Uploaded: {formik.values.Documentation[0]?.TrustSettlement_document.name}</div>
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => handleRemoveFile('TrustSettlement_document')}
                                  >
                                    Remove File
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="d-flex my-2">
                            <span style={{ minWidth: '50px' }} className=''>11.</span>
                            <div>
                              <label for="Onboarding" class="form-label business-input-headings">Onboarding Form followed by you duly completed</label>
                              <input
                                type="file"
                                name='OnboardingForm_document'
                                className="form-control business-input-group-box"
                                id="Onboarding"
                                accept="image/*,application/pdf"
                                onChange={imagehandleChange}
                                onBlur={formik.handleBlur}
                                ref={fileInputRef}
                              />
                              {formik.touched.Documentation?.[0]?.OnboardingForm_document && formik.errors.Documentation?.[0]?.OnboardingForm_document && (
                                <div className="text-danger rq-msg">{formik.errors.Documentation[0].OnboardingForm_document}</div>
                              )}
                              {filePreviews?.OnboardingForm_document && (
                                <div className="my-2">
                                  <div>Uploaded: {formik.values.Documentation[0]?.OnboardingForm_document.name}</div>
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => handleRemoveFile('OnboardingForm_document')}
                                  >
                                    Remove File
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="d-flex my-2">
                            <span style={{ minWidth: '50px' }} className=''>12.</span>
                            <div>
                              <label for="Annual" class="form-label business-input-headings">Annual financial (accounting) statements</label>
                              <input
                                type="file"
                                name='Annualfinancial_document'
                                className="form-control business-input-group-box"
                                id="Annual"
                                accept="image/*,application/pdf"
                                onChange={imagehandleChange}
                                onBlur={formik.handleBlur}
                                ref={fileInputRef}
                              />
                              {formik.touched.Documentation?.[0]?.Annualfinancial_document && formik.errors.Documentation?.[0]?.Annualfinancial_document && (
                                <div className="text-danger rq-msg">{formik.errors.Documentation[0].Annualfinancial_document}</div>
                              )}
                              {filePreviews?.Annualfinancial_document && (
                                <div className="my-2">
                                  <div>Uploaded: {formik.values.Documentation[0]?.Annualfinancial_document.name}</div>
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => handleRemoveFile('Annualfinancial_document')}
                                  >
                                    Remove File
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="d-flex my-2">
                            <span style={{ minWidth: '50px' }} className=''>13.</span>
                            <div>
                              <label for="Audited" class="form-label business-input-headings">Audited financial (accounting) statements for the last accounting period</label>
                              <input
                                type="file"
                                name='Auditedfinancial_document'
                                className="form-control business-input-group-box"
                                id="Audited"
                                accept="image/*,application/pdf"
                                onChange={imagehandleChange}
                                onBlur={formik.handleBlur}
                                ref={fileInputRef}
                              />
                              {formik.touched.Documentation?.[0]?.Auditedfinancial_document && formik.errors.Documentation?.[0]?.Auditedfinancial_document && (
                                <div className="text-danger rq-msg">{formik.errors.Documentation[0].Auditedfinancial_document}</div>
                              )}
                              {filePreviews?.Auditedfinancial_document && (
                                <div className="my-2">
                                  <div>Uploaded: {formik.values.Documentation[0]?.Auditedfinancial_document.name}</div>
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => handleRemoveFile('Auditedfinancial_document')}
                                  >
                                    Remove File
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="d-flex my-2">
                            <span style={{ minWidth: '50px' }} className=''>14.</span>
                            <div>
                              <label for="letter" class="form-label business-input-headings">In the absence of the documents referred to in paragraphs 12-13, an official letter, containing the reasons for the
                                absence of these documents, shall be submitted</label>
                              <input
                                type="file"
                                name='absenceof_document'
                                className="form-control business-input-group-box"
                                id="letter"
                                accept="image/*,application/pdf"
                                onChange={imagehandleChange}
                                onBlur={formik.handleBlur}
                                ref={fileInputRef}
                              />
                              {formik.touched.Documentation?.[0]?.absenceof_document && formik.errors.Documentation?.[0]?.absenceof_document && (
                                <div className="text-danger rq-msg">{formik.errors.Documentation[0].absenceof_document}</div>
                              )}
                              {filePreviews?.absenceof_document && (
                                <div className="my-2">
                                  <div>Uploaded: {formik.values.Documentation[0]?.absenceof_document.name}</div>
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => handleRemoveFile('absenceof_document')}
                                  >
                                    Remove File
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="d-flex my-2">
                            <span style={{ minWidth: '50px' }} className=''>15.</span>
                            <div>
                              <label for="Passport" class="form-label business-input-headings">Identity Card or Passport of representative/s (director/s) and Utility Bill of representative/s (director/s)</label>
                              <input
                                type="file"
                                name='IdentityCardorPassport_document'
                                className="form-control business-input-group-box"
                                id="Passport"
                                accept="image/*,application/pdf"
                                onChange={imagehandleChange}
                                onBlur={formik.handleBlur}
                                ref={fileInputRef}
                              />
                              {formik.touched.Documentation?.[0]?.IdentityCardorPassport_document && formik.errors.Documentation?.[0]?.IdentityCardorPassport_document && (
                                <div className="text-danger rq-msg">{formik.errors.Documentation[0].IdentityCardorPassport_document}</div>
                              )}
                              {filePreviews?.IdentityCardorPassport_document && (
                                <div className="my-2">
                                  <div>Uploaded: {formik.values.Documentation[0]?.IdentityCardorPassport_document.name}</div>
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => handleRemoveFile('IdentityCardorPassport_document')}
                                  >
                                    Remove File
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="d-flex my-2">
                            <span style={{ minWidth: '50px' }} className=''>16.</span>
                            <div>
                              <label for="address" class="form-label business-input-headings">Details of the Company’s UBO’s including certified copy of passport, and proof of permanent address.</label>
                              <input
                                type="file"
                                name='permanentaddress_document'
                                className="form-control business-input-group-box"
                                id="address"
                                accept="image/*,application/pdf"
                                onChange={imagehandleChange}
                                onBlur={formik.handleBlur}
                                ref={fileInputRef || null}
                              />
                              {formik.touched.Documentation?.[0]?.permanentaddress_document && formik.errors.Documentation?.[0]?.permanentaddress_document && (
                                <div className="text-danger rq-msg">{formik.errors.Documentation[0].permanentaddress_document}</div>
                              )}
                              {filePreviews?.permanentaddress_document && (
                                <div className="my-2">
                                  <div>Uploaded: {formik.values.Documentation[0]?.permanentaddress_document.name}</div>
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => handleRemoveFile('permanentaddress_document')}
                                  >
                                    Remove File
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="d-flex my-2">
                            <span style={{ minWidth: '50px' }} className=''>17.</span>
                            <div>
                              <label for="confirmation" class="form-label business-input-headings">Account opening confirmation </label>
                              <input
                                type="file"
                                name='Accountopening_document'
                                className="form-control business-input-group-box"
                                id="confirmation"
                                accept="image/*,application/pdf"
                                onChange={imagehandleChange}
                                onBlur={formik.handleBlur}
                                ref={fileInputRef}
                              />
                              {formik.touched.Documentation?.[0]?.Accountopening_document && formik.errors.Documentation?.[0]?.Accountopening_document && (
                                <div className="text-danger rq-msg">{formik.errors.Documentation[0].Accountopening_document}</div>
                              )}
                              {filePreviews?.Accountopening_document && (
                                <div className="my-2">
                                  <div>Uploaded: {formik.values.Documentation[0]?.Accountopening_document.name}</div>
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => handleRemoveFile('Accountopening_document')}
                                  >
                                    Remove File
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="d-flex my-2">
                            <span style={{ minWidth: '50px' }} className=''>18.</span>
                            <div>
                              <label for="AML_CTF_document" class="form-label business-input-headings">AML/CTF program <span className='option-css'>(if applicable)</span></label>
                              <input
                                type="file"
                                name='AML_CTF_document'
                                className="form-control business-input-group-box"
                                id="AML_CTF_document"
                                accept="image/*,application/pdf"
                                onChange={imagehandleChange}
                                onBlur={formik.handleBlur}
                                ref={fileInputRef}
                              />
                              {filePreviews?.AML_CTF_document && (
                                <div className="my-2">
                                  <div>Uploaded: {formik.values.Documentation[0]?.AML_CTF_document.name}</div>
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => handleRemoveFile('AML_CTF_document')}
                                  >
                                    Remove File
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                          <p>All documents shall be less than 6 months old from the date this questionnaire is signed,  presented in hard copies duly certified before any operational activities.</p>


                        </div>

                        <div>
                          <h6 class=" text-center my-4 business-input-headings-one">DECLARATIONS AND SIGNATURE</h6>
                          <ul>
                            <li className='my-2'>we are acting on our own account and not on behalf of any other person;</li>
                            <li className='my-2'>any funds that are received by us in the future will not be used for the financing of terrorism or with any fraudulent activities; and</li>
                            <li className='my-2'>we have not in the past committed any irregularities or fraud in breach of anti-money laundering and financing of terrorism applicable legislation.</li>
                          </ul>
                          <p>In addition, we hereby declare that the details and declarations provided in this Onboarding Form and any documents provided together with this form are, to the best of our knowledge and belief, true, accurate, correct, complete and not misleading and we undertake to immediately inform you about any changes. If any such details, declarations or documents cease to be true, accurate, correct, complete or not misleading, we are aware that we may be held liable for this.</p>
                          <p>I have answered all questions and understand that my application is subject to verification and approval by REM INTERNATIONAL PAYMENTS INC., reserves the right to decline the application, if it is found to be incomplete or ineligible, and further requests the right to request additional information/documentation as part of the Know Your Customer principles and best practices, or even deny processing to any merchant at its own discretion.</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <=========================> */}

                  <div className='d-flex align-items-center justify-content-md-between flex-wrap req-doc'>

                    <div className=''>

                      <div className='my-2'>Date :</div>
                      <div className='my-4'>stamp :</div>

                    </div>
                    <div className=''>

                      <label for="Name/signatureuuu" class="form-label business-input-headings">(Name, director/representative signature)</label>
                      <input
                        type="file"
                        className="form-control business-input-group-box"
                        name='signature'
                        id="Name/signature"
                        accept="image/*"
                        onChange={imagehandleChange}
                        onBlur={formik.handleBlur}
                        ref={fileInputRef}
                      />
                      {formik.touched.Documentation?.[0]?.signature && formik.errors.Documentation?.[0]?.signature && (
                        <div className="text-danger rq-msg">{formik.errors.Documentation[0].signature}</div>
                      )}
                      {filePreviews?.signature && (
                        <div className="my-2">
                          <div>Uploaded: {formik.values.Documentation[0]?.signature.name}</div>
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => handleRemoveFile('signature')}
                          >
                            Remove File
                          </button>
                        </div>
                      )}

                    </div>
                  </div>

                  <div className='row justify-content-center'>
                    <div className='col-12 col-lg-6'>
                      <div className='my-5'>
                        {isLoading == true ? (
                          <button class="d-send-btn-1 rounded-pill" type="submit">
                            <Spinner animation="border" />
                          </button>
                        ) : (

                          localStorage.getItem("userCredentials") ? (
                            <button class="d-send-btn-1 rounded-pill" >
                              Submit
                            </button>
                          ) : (
                            <></>
                          )


                        )}
                      </div>
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>



        </div>
      </div>
    </div>
  );
}

export default Business;
{/* <div class="my-4"><label for="formGroupExampleInput" class="form-label
 fw-bold">History, reputation, segment of the market and competitors 
of the Company</label><input type="text" class="form-control" id="formGroupExampleInput"
 placeholder=""></div> */ }