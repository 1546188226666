import React from 'react'
import logo from '../assets/images/dashboard/card/favicon.png';

function Load() {
    return (
        <div className='load-page'>
            <div className='container-fluid' style={{ paddingTop: "200px" }}>
                <div class="d-flex justify-content-center align-items-center ">
                    <div class="text-center div-parent">
                        <img src={logo} alt='logo' className='loader-img square'></img>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Load