import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { makeApiRequest } from '../../../config/AxiosService';
import "../../../assets/css/demo.css"
import { useNavigate } from 'react-router-dom';
import DataContext from "../../../context/DataContext"
import { Spinner } from 'react-bootstrap';

function Legalentity({ loggedin, handleParentSubmit, handlePrevious }) {
    const navigate = useNavigate();
    const { setIsLogedIn } = useContext(DataContext);
    const [indivLegalData, setIndivLegalData] = useState({})
    const [legalData, setLegalData] = useState({
        legal_Entity: {
            full_name: "",
            abbrevation_Name: "",
            foreign_lang_name: "",
            data: "",
            register_numb: "",
            register_authority: "",
            register_place: "",
            legal_address: "",
            postal_address: "",
            email_or_url: "",
            tax_residency: "",
            tax_number: "",
            shares: "",
            shareHolder: false,
            date: "",
        },
    });

    const toggleLogin = () => {
        setIsLogedIn(true);
        navigate('/');
    };

    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue: setLegalValue,
        trigger,
        clearErrors
    } = useForm({
        mode: "onChange",
        defaultValues: {
            shareHolder: indivLegalData?.shareHolder || false
        }
    });

    const handleChange = (field, name, value) => {
        setLegalData((prevFormData) => ({
            ...prevFormData,
            [field]: {
                ...prevFormData[field],
                [name]: value,
            },
        }));
    };

    const onSubmit = (data) => {
        setLegalData((prevFormData) => ({
            ...prevFormData,
            legal_Entity: {
                full_name: data.full_name,
                abbrevation_Name: data.abbrevation_Name,
                foreign_lang_name: data.foreign_lang_name || "",
                data: data.abbrevation_Name,
                register_numb: data.register_numb,
                register_authority: data.register_authority,
                register_place: data.register_place,
                legal_address: data.legal_address,
                postal_address: data.postal_address,
                email_or_url: data.email_or_url,
                tax_residency: data.tax_residency,
                tax_number: data.tax_number,
                shares: data.shares,
                shareHolder: data.shareHolder,
                date: data.date,
            },
        }));

        handleParentSubmit({
            // individualStatus: 0,
            legal_Entity: {
                full_name: data.full_name,
                abbrevation_Name: data.abbrevation_Name,
                foreign_lang_name: data.foreign_lang_name || "",
                data: data.abbrevation_Name,
                register_numb: data.register_numb,
                register_authority: data.register_authority,
                register_place: data.register_place,
                legal_address: data.legal_address,
                postal_address: data.postal_address,
                email_or_url: data.email_or_url,
                tax_residency: data.tax_residency,
                tax_number: data.tax_number,
                shares: data.shares,
                shareHolder: data.shareHolder,
                date: data.date,
            },
        });
    };

    useEffect(() => {
        const getLegalData = async () => {
            try {
                let params = {
                    url: "user-individual-data",
                    method: "GET"
                }
                const resp = await makeApiRequest(params)
                if (resp.status) {
                    const response = resp.resp.legal_Entity
                    setIndivLegalData(response)
                } else {
                    setIndivLegalData({})
                }
            } catch (error) {
                setIndivLegalData({})
            }
        }

        getLegalData()
    }, [])

    useEffect(() => {
        if (indivLegalData) {
            setLegalValue("full_name", indivLegalData.full_name)
            setLegalValue("abbrevation_Name", indivLegalData.abbrevation_Name)
            setLegalValue("foreign_lang_name", indivLegalData.foreign_lang_name || "")
            setLegalValue("data", indivLegalData.data)
            setLegalValue("register_numb", indivLegalData.register_numb)
            setLegalValue("register_authority", indivLegalData.register_authority)
            setLegalValue("register_place", indivLegalData.register_place)
            setLegalValue("legal_address", indivLegalData.legal_address)
            setLegalValue("postal_address", indivLegalData.postal_address)
            setLegalValue("email_or_url", indivLegalData.email_or_url)
            setLegalValue("tax_residency", indivLegalData.tax_residency)
            setLegalValue("tax_number", indivLegalData.tax_number)
            setLegalValue("shares", indivLegalData.shares)
            setLegalValue("shareHolder", indivLegalData.shareHolder == true ? "true" : "false")
            setLegalValue("date", indivLegalData.date)
        }
    }, [indivLegalData])

    return (
        <div>
            <form className='Legalentity' id='legalEntity_form' onSubmit={handleSubmit(onSubmit)} >
                <div className='text-end mt-4'>
                    <button type="button" className="bef-b-login-1 w-auto" onClick={toggleLogin} >
                        Skip
                    </button>
                </div>
                {/* <h4 className='mb-auto text-center business-title text-uppercase py-4'>Questionnaire for Shareholders and Administrative bodies</h4> */}
                <h4 className='mb-auto text-center business-title text-uppercase py-4'>(legal entity)</h4>
                <div className="row">
                    <div className="col-lg-12">
                        <h4 className='business-subtitle'>Name of the legal entity</h4>
                        <div className="mb-4 pt-2">
                            <label htmlFor="full_name" className="form-label">Full name :</label>
                            <input
                                type="text"
                                className="form-control business-input-group-box"
                                placeholder="Full name"
                                id="full_name"
                                name="full_name"
                                {...register("full_name", {
                                    required: {
                                        value: true,
                                        message: 'Enter Your Full Name..',
                                    },
                                    pattern: {
                                        value: /^[A-Za-z. ]*$/,
                                        message: "Invalid Name Format"
                                    },
                                    onChange: (e) => {
                                        handleChange("legal_Entity", "full_name", e.target.value);
                                        clearErrors("full_name");
                                    },
                                    onBlur: async (e) => {
                                        await trigger(e.target.name)
                                    }
                                })}
                            />
                            {errors.full_name && (
                                <div className="text-danger rq-msg">{errors.full_name.message}</div>
                            )}
                        </div>
                        <div className="mb-4 pt-2">
                            <label htmlFor="abbrevation_Name" className="form-label">Abbreviate name :<span className='option-css'>(if applicable):</span></label>
                            <input
                                type="text"
                                className="form-control business-input-group-box"
                                id="abbrevation_Name"
                                placeholder="Abbreviate name"
                                name='abbrevation_Name'
                                {...register("abbrevation_Name", {

                                    onChange: (e) => {
                                        handleChange("legal_Entity", "abbrevation_Name", e.target.value);
                                        clearErrors("abbrevation_Name");
                                    },

                                })}
                            />
                            {errors.abbrevation_Name && <div className="text-danger rq-msg">{errors.abbrevation_Name.message}</div>}
                        </div>
                        <div className="mb-4 pt-2">
                            <label htmlFor="foreign_lang_name" className="form-label">Foreign languages name <span className='option-css'>(if applicable):</span> </label>
                            <input
                                type="text"
                                className="form-control business-input-group-box"
                                id="foreign_lang_name"
                                placeholder="Languages name"
                                name="foreign_lang_name"
                                {...register("foreign_lang_name", {
                                    onChange: (e) => {
                                        handleChange("legal_Entity", "foreign_lang_name", e.target.value)
                                    }
                                })}

                            />
                        </div>

                    </div>
                </div>

                <div className='row'>
                    <div className="col-lg-12">
                        <h4 className='business-subtitle'>State registration information</h4>
                        <div className="mb-4 pt-2">
                            <label htmlFor="data" className="form-label">Data :</label>
                            <input
                                type="text"
                                className="form-control business-input-group-box"
                                id="data"
                                placeholder="Data"
                                name="data"
                                {...register("data", {
                                    required: 'Enter The Data..',
                                    onChange: (e) => {
                                        handleChange("legal_Entity", "data", e.target.value);
                                        clearErrors("data");
                                    },
                                    onBlur: async (e) => {
                                        await trigger(e.target.name)
                                    }
                                })}
                            />
                            {errors.data && <div className="text-danger rq-msg">{errors.data.message}</div>}
                        </div>
                        <div className="mb-4 pt-2">
                            <label htmlFor="register_numb" className="form-label">Registration number  :</label>
                            <input
                                type="text"
                                className="form-control business-input-group-box"
                                id="register_numb"
                                placeholder="Registration number"
                                name="register_numb"
                                {...register("register_numb", {
                                    required: 'Enter Your Registration Number..',
                                    pattern: {
                                        value: /^[A-Za-z0-9\s]+$/,
                                        message: 'Special characters are not allowed.',
                                    },
                                    onChange: (e) => {
                                        handleChange("legal_Entity", "register_numb", e.target.value);
                                        clearErrors("register_numb");
                                    },
                                    onBlur: async (e) => {
                                        await trigger(e.target.name)
                                    }
                                })}
                            />
                            {errors.register_numb && <div className="text-danger rq-msg">{errors.register_numb.message}</div>}
                        </div>
                        <div className="mb-4 pt-2">
                            <label htmlFor="register_authority" className="form-label">Name of the registration authority  :</label>
                            <input
                                type="text"
                                className="form-control business-input-group-box"
                                id="register_authority"
                                placeholder="Name of the registration authority"
                                name="register_authority"
                                {...register("register_authority", {
                                    required: 'Enter the Name of the registration authority..',
                                    pattern: {
                                        value: /^[A-Za-z\s.,-]+$/,
                                        message: 'Invalid format',
                                    },
                                    onChange: (e) => {
                                        handleChange("legal_Entity", "register_authority", e.target.value);
                                        clearErrors("register_authority");
                                    },
                                    onBlur: async (e) => {
                                        await trigger(e.target.name)
                                    }
                                })}
                            />
                            {errors.register_authority && <div className="text-danger rq-msg">{errors.register_authority.message}</div>}
                        </div>
                        <div className="mb-4 pt-2">
                            <label htmlFor="register_place" className="form-label">Place of registration  :</label>
                            <input
                                type="text"
                                className="form-control business-input-group-box"
                                id="register_place"
                                placeholder="Place of registration"
                                name="register_place"
                                {...register("register_place", {
                                    required: 'Enter the Place of registration..',
                                    pattern: {
                                        value: /^[A-Za-z\s.,-]+$/,
                                        message: 'Invalid format',
                                    },
                                    onChange: (e) => {
                                        handleChange("legal_Entity", "register_place", e.target.value);
                                        clearErrors("register_place");
                                    },
                                    onBlur: async (e) => {
                                        await trigger(e.target.name)
                                    }
                                })}
                            />
                            {errors.register_place && <div className="text-danger rq-msg">{errors.register_place.message}</div>}
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className="col-lg-12">
                        <div className="mb-4 pt-2">
                            <label htmlFor="legal_address" className="form-label">Legal address :</label>
                            <textarea
                                type="text"
                                className="form-control business-input-group-box"
                                id="legal_address"
                                placeholder="Legal address"
                                name="legal_address"
                                {...register("legal_address", {
                                    required: 'Required Legal address..',
                                    pattern: {
                                        value: /^[A-Za-z0-9.,#\-\/\s]+$/,
                                        message: "Invalid Address Format"
                                    },
                                    onChange: (e) => {
                                        handleChange("legal_Entity", "legal_address", e.target.value);
                                        clearErrors("legal_address");
                                    },
                                    onBlur: async (e) => {
                                        await trigger(e.target.name)
                                    }
                                })}
                            />
                            {errors.legal_address && <div className="text-danger rq-msg">{errors.legal_address.message}</div>}
                        </div>
                        <div className="mb-4 pt-2">
                            <label htmlFor="postal_address" className="form-label">Postal address :</label>
                            <textarea
                                type="text"
                                className="form-control business-input-group-box"
                                id="postal_address"
                                placeholder="Postal address"
                                name="postal_address"
                                {...register("postal_address", {
                                    required: 'Required Postal address..',
                                    pattern: {
                                        value: /^[A-Za-z0-9.,#\-\/\s]+$/,
                                        message: "Invalid Address Format"
                                    },
                                    onChange: (e) => {
                                        handleChange("legal_Entity", "postal_address", e.target.value);
                                        clearErrors("postal_address");
                                    },
                                    onBlur: async (e) => {
                                        await trigger(e.target.name)
                                    }
                                })}
                            />
                            {errors.postal_address && <div className="text-danger rq-msg">{errors.postal_address.message}</div>}
                        </div>
                        <div className="mb-4 pt-2">
                            <label htmlFor="email_or_url" className="form-label">E-mail or URL :</label>
                            <input
                                type="text"
                                className="form-control business-input-group-box"
                                id="email_or_url"
                                placeholder="E-mail, URL"
                                name="email_or_url"
                                {...register("email_or_url", {
                                    required: {
                                        value: true,
                                        message: 'Enter Email or URL...',
                                    },
                                    validate: (value) => {
                                        const validEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                        const validUrl = /^(https:\/\/|www\.)[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

                                        if (validEmail.test(value) || validUrl.test(value)) {
                                            return true;
                                        }
                                        return 'Enter a valid Email or URL';
                                    },
                                    onChange: (e) => {
                                        handleChange("legal_Entity", "email_or_url", e.target.value);
                                        clearErrors("email_or_url");
                                    },
                                    onBlur: async (e) => {
                                        await trigger(e.target.name)
                                    }
                                })}
                            />
                            {errors.email_or_url && <div className="text-danger rq-msg">{errors.email_or_url.message}</div>}
                        </div>

                    </div>
                </div>

                <div className='row'>
                    <div className="col-lg-6 col-12 mb-3">
                        <label className="mb-2  form-label">Country of Tax Residency :</label>
                        <input
                            type="text"
                            className="form-control business-input-group-box"
                            placeholder="Tax Residency"
                            name="tax_residency"
                            {...register("tax_residency", {
                                required: 'Enter a valid Country ',
                                pattern: {
                                    value: /^[A-Za-z\s]+$/,
                                    message: 'Only alphabetic characters are allowed',
                                },
                                onChange: (e) => {
                                    handleChange("legal_Entity", "tax_residency", e.target.value);
                                    clearErrors("tax_residency");
                                },
                                onBlur: async (e) => {
                                    await trigger(e.target.name)
                                }
                            })}
                        />
                        {errors.tax_residency && <div className="text-danger rq-msg">{errors.tax_residency.message}</div>}
                    </div>
                    <div className="col-lg-6 col-12 mb-3">
                        <label className="mb-2  form-label">TAX number :</label>
                        <input
                            type="text"
                            className="form-control business-input-group-box"
                            placeholder="TAX number"
                            name="tax_number"
                            {...register("tax_number", {
                                required: 'Enter the TAX number...',
                                // pattern: {
                                //     value: /^[A-Za-z0-9]{9,10}$/,
                                //     message: 'Tax Number must be 9 to 10 alphanumeric characters.',
                                // },
                                onChange: (e) => {
                                    handleChange("legal_Entity", "tax_number", e.target.value);
                                    clearErrors("tax_number");
                                },
                                onBlur: async (e) => {
                                    await trigger(e.target.name)
                                }
                            })}
                        />
                        {errors.tax_number && <div className="text-danger rq-msg">{errors.tax_number.message}</div>}
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="mb-4 pt-2">
                            <label htmlFor="shares" className="form-label">Percentage and number of shares in the share capital of the Company</label>
                            <input
                                type="text"
                                className="form-control business-input-group-box"
                                id="shares"
                                placeholder="Enter"
                                name="shares"
                                {...register("shares", {
                                    required: 'Enter the Share Capital of Company..',
                                    onChange: (e) => {
                                        handleChange("legal_Entity", "shares", e.target.value);
                                        clearErrors("shares");
                                    },
                                    onBlur: async (e) => {
                                        await trigger(e.target.name)
                                    }
                                })}
                            />
                            {errors.shares && <div className="text-danger rq-msg">{errors.shares.message}</div>}
                        </div>

                        <label htmlFor="sharehold" className="form-label">Is the Shareholder acting as a nominee?</label>
                        <div className="d-flex mb-4">
                            <div className="form-check me-3">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="shareHolder"
                                    id="shareHolderYes"
                                    value="true"
                                    {...register('shareHolder', {
                                        required: "Select The Option"
                                    })}
                                />
                                <label className="form-check-label" htmlFor="shareHolderYes">
                                    Yes
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="shareHolder"
                                    id="shareHolderNo"
                                    value="false"
                                    {...register('shareHolder', {
                                        required: "Select The Option"
                                    })}
                                />
                                <label className="form-check-label" htmlFor="shareHolderNo">
                                    No
                                </label>
                            </div>
                        </div>
                        {errors.shareHolder && <div className="text-danger">{errors.shareHolder.message}</div>}
                        <div className="mb-4 pt-2">
                            <label htmlFor="date" className="form-label  business-input-headings">Date :</label>
                            <input
                                type="date"
                                className="form-control business-input-group-box"
                                id="date"
                                placeholder="Date"
                                name="date"
                                {...register("date", {
                                    required: 'Select the Date..',
                                    onChange: (e) => {
                                        handleChange("legal_Entity", "date", e.target.value);
                                        clearErrors("date");
                                    },
                                    onBlur: async (e) => {
                                        await trigger(e.target.name)
                                    }
                                })}
                            />
                            {errors.date && <div className="text-danger rq-msg">{errors.date.message}</div>}
                        </div>

                        <div className='row'>
                            <div className='col-lg-6 col-12 mt-4'>
                                <div>stamp:</div>
                            </div>
                            <div className='col-lg-6 col-12 mt-4'>
                                <div>
                                    <p className='text-end'> (Name, director/representative signature)</p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <div className='d-flex align-items-center justify-content-between py-5'>
                    <div><button type="button" className="bef-b-login-1 w-auto me-2" onClick={handlePrevious}>
                        Previous
                    </button></div>
                    {loggedin == true ? (

                        <button className='bef-b-login-1 w-auto px-5 py-1' type="button">
                            <Spinner animation="border" />
                        </button>

                    ) : (
                        <div className='text-end py-3'>
                            <button type="submit" className="bef-b-login-1 w-auto"  >
                                Save & Next
                            </button>
                        </div>
                    )}
                </div>

            </form>
        </div>
    )
}

export default Legalentity